import { auth } from '../firebase/firebaseConfig';
import { api } from './api';
interface PromptBody {
    message: string;
    type: string;
}
interface PromptInterface {
    chat: PromptBody[];
}


const getCatalogApi = async (params: string, RequestBody: any): Promise<any> => {
    const coordinates = RequestBody.coordinates
    const url: string = `/api/v2/catalog/available-data`;
    const dataFeatures = {
        features: [{
            type: "Feature",
            properties: {},
            geometry: {
                type: "Polygon",
                coordinates
            }
        }],
        grouping: true,
        type: params
    };
    // console.log(dataFeatures);
    const { data } = await api.post<any>(url, dataFeatures);
    return data;
};

const addToChart = async (token: string, body: any): Promise<any> => {
    const url: string = '/api/v2/shop/cart'
    try {
        const { data } = await api.post<any>(url, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (error) {
        console.error('Error occurred while add product to chart:', error);
        throw error;
    }
};

const getCart = async (): Promise<any> => {
    const user = auth.currentUser;
    const token = await user?.getIdToken(); // Only get token if user exists
    const url: string = '/api/v2/shop/cart'
    try {
        const { data } = await api.get<any>(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (error) {
        console.error('Error occurred while get cart:', error);
        throw error;
    }
};

const postPromptUser = async (prompt: string): Promise<any> => {
    const promptAi = {
        chat: [
            {
                message: prompt,
                type: 'user'
            }
        ]
    }
    const url: string = 'api/v2/catalog/recomendation';
    const requestBody: PromptInterface = promptAi;

    try {
        const { data } = await api.post<any>(url, requestBody);
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error occurred while posting prompt:', error);
        throw error;
    }
};

const getMyDatas = async (params: string): Promise<any> => {
    const user = auth.currentUser;
    const token = await user?.getIdToken(); // Only get token if user exists
    const url: string = `/api/v2/user/mydata/category?type=${params}`;
    const { data } = await api.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
};

const getDetailMyDatas = async (params: string): Promise<any> => {
    const user = auth.currentUser;
    const token = await user?.getIdToken(); // Only get token if user exists
    const url: string = `/api/v2/user/mydata/product?category=${params}`;
    const { data } = await api.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
};

const getDetailInvoice = async (params: string): Promise<any> => {
    const url: string = `/api/v2/xendit/${params}`;
    const { data } = await api.get<any>(url);
    return data?.data;
};

const getIsochrones = async (coor: any): Promise<any> => {
    const url: string = `/api/v2/catalog/isochrones`;
    const dataFeatures = {
        "coordinates": [
            coor?.lng,
            coor?.lat
        ],
    };
    const { data } = await api.post<any>(url, dataFeatures);
    return data;
};

const getDetailInvoiceforLinkData = async (params: string): Promise<any> => {
    const user = auth.currentUser;
    const token = await user?.getIdToken(); // Only get token if user exists
    const url: string = `/api/v2/user/mydata/product?transaction=${params}`;
    const { data } = await api.get<any>(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
};

const downloadData = async (body: any): Promise<any> => {
    const user = auth.currentUser;
    const token = await user?.getIdToken(); // Only get token if user exists
    const url: string = '/api/v2/user/mydata/geturl'
    try {
        const { data } = await api.post<any>(url, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (error) {
        console.error('Error occurred while add product to download:', error);
        throw error;
    }
};

const generateDescription = async (body: any): Promise<any> => {
    const user = auth.currentUser;
    const token = await user?.getIdToken(); // Only get token if user exists
    const url: string = '/api/v2/result/description'
    try {
        const { data } = await api.post<any>(url, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (error) {
        console.error('Error occurred while generate description:', error);
        throw error;
    }
};


const catalogServicesApi = {
    getCatalogApi,
    addToChart,
    getCart,
    postPromptUser,
    getMyDatas,
    getDetailMyDatas,
    getDetailInvoice,
    getIsochrones,
    getDetailInvoiceforLinkData,
    downloadData,
    generateDescription
};

export default catalogServicesApi;