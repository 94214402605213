import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import zoomInIcon from '../../../asesst/Icons/interactivemap/icon/layer1-white.svg';
import zoomOutIcon from '../../../asesst/Icons/interactivemap/icon/layer2-white.svg';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';

interface ZoomToolsProps {
  map: maplibregl.Map | null; // Adjust this type depending on the map library you are using
}

export const ZoomTools: React.FC<ZoomToolsProps> = ({ map }) => {
  const {
    dataLayerGroup,
  } = useReduxCatalog();

  const handleZoomIn = () => {
    map?.zoomIn(); // Zoom in the map
  };

  const handleZoomOut = () => {
    map?.zoomOut(); // Zoom out the map
  };

  return (
    <React.Fragment>
      <Space
        direction='vertical'
        style={{
          position: 'absolute',
          right:  dataLayerGroup?.length > 0 ? '190px' : '140px',
          top: 20,
          zIndex: 100,
          transition: '0.25s ease-in-out',
        }}
      >
        <Tooltip 
          title={<p style={{ color: 'black' }}>Zoom In</p>}
          placement="right"
          color='white'
        >
          <Button
            id='end-point'
            onClick={handleZoomIn} // Handle zoom in
            style={{
              backgroundColor: '#141617',
              color: 'black',
              height: "50px",
              width: "50px",
              borderRadius: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img 
              src={zoomInIcon}
              alt="Zoom In Icon" 
            />
          </Button>
        </Tooltip>
      </Space>

      <Space
        direction='vertical'
        style={{
          position: 'absolute',
          right:  dataLayerGroup?.length > 0 ? '140px' : '90px',
          top: 20,
          zIndex: 100,
          transition: '0.25s ease-in-out',
        }}
      >
        <Tooltip 
          title={<p style={{ color: 'black' }}>Zoom Out</p>}
          placement="right"
          color='white'
        >
          <Button
            id='end-point'
            onClick={handleZoomOut} // Handle zoom out
            style={{
              backgroundColor: '#141617',
              color: 'black',
              height: "50px",
              width: "50px",
              borderTopLeftRadius: '0px',
              borderTopRightRadius:'12px',
              borderBottomLeftRadius:'0px',
              borderBottomRightRadius:'12px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img 
              src={zoomOutIcon}
              alt="Zoom Out Icon" 
            />
          </Button>
        </Tooltip>
      </Space>
    </React.Fragment>
  );
};
