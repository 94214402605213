export const formatNumberWithDots = (number: number | undefined): string => {
    if (number === undefined) {
        return '0'; // or another default string if you want to handle undefined differently
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatNumberSqm = (num: number) => {
    return parseFloat(num.toFixed(2));
};

export const formatNumberReachSqm = (value: number): string => {
    if (value === 0) return '0';  // Jika nilai 0, langsung kembalikan '0'
    
    const formattedValue = (value / 100000).toFixed(1);
    return formattedValue;
  };
  
  export const formatNumberReachKm2 = (value: number): string => {
    if (value === 0) return '0 km²'; // Jika nilai 0, langsung kembalikan '0 km²'
  
    const formattedValue = (value / 1_000_000).toFixed(1); // Konversi ke km² dan format ke 1 desimal
    return `${formattedValue} km²`;
  };

  
  export const convertToKm2 = (value: number) => {
    return value / 1_000_000;
}


export const formatRangeNumbers = (ranges: string[]): string[] => {
    return ranges?.map((range) => {
      // Jika range menggunakan format "> value"
      if (range.startsWith('>')) {
        const value = parseInt(range.replace('>', '').trim(), 10); // Ambil nilai angka setelah '>'
        return value > 20000 ? `Rp > ${formatNumberWithDots(value)}` : `Rp > ${formatNumberWithDots(value)}`;
      }
  
      // Jika range menggunakan format "start - end"
      const [start, end] = range.split(' - ').map((num) => parseInt(num, 10));
  
      // Format default dengan range
      return `Rp ${formatNumberWithDots(start)} - Rp ${formatNumberWithDots(end)}`;
    });
  };

  export const formatRangeNumbersKm2distribution = (ranges: string[]): string[] => {
    return ranges?.map((range) => {
      // Jika range menggunakan format "> value"
      if (range.startsWith('>')) {
        const value = parseFloat(range.replace('>', '').trim()) / 1_000_000; // Konversi ke juta
        return `> ${value.toFixed(1)} km²`;
      }
  
      // Jika range menggunakan format "start - end"
      const [start, end] = range
        .split(' - ')
        .map((num) => parseFloat(num.trim()) / 1_000_000); // Konversi ke juta
  
      // Format default dengan range
      return `${start.toFixed(1)} - ${end.toFixed(1)} km²`;
    });
  };
  
  
  