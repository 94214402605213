import React, { useEffect } from 'react';
import { Card, Button, notification } from 'antd';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import icDownload from '../../../asesst/Icons/interactivemap/icon/ic-download.svg';
import closeIcon from '../../../asesst/Icons/interactivemap/icon/close-tools.svg';
export const CardListdownload: React.FC<any> = () => {
    const {
        setDownloadData,
        setShowCardDownload,
        downloadData,
        metaData,
    } = useReduxCatalog();
    useEffect(() => {
        // Cek apakah data sudah ada
        if (metaData?.other_format && typeof metaData.other_format === 'object') {
            //   setIsLoading(false); // Matikan loading
        }
    }, [metaData]);
    const handleDownloadClick = async (data: any) => {
        try {
            await setDownloadData({
                url: data.urls,
            });

            if (downloadData) {
                window.open(downloadData?.url, '_blank');
            }

        } catch (error) {
            notification.error({
                style: {
                    backgroundColor: '#333939',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to download data. Please try again latter</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
        }
    }
    const handleClose = () => {
        setShowCardDownload(false)
    };

    return (
        <div style={{
            overflowY: 'auto',
            paddingRight: '8px',
            scrollbarWidth: 'none', /* Firefox */
            msOverflowStyle: 'none' /* IE and Edge */,
        }}>
            <div

                className="custom-card"
            >

                <Card
                    style={{
                        width: 268,
                        backgroundColor: '#141617',
                        color: 'white',
                        cursor: 'pointer',
                    }}
                >
                    <p style={{ color: 'white', marginBottom: '10px', textAlign: 'left', fontSize: '14px', fontWeight: 'bold' }}>Select Type Data</p>
                    <Button
                        type="text"
                        style={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            fontSize: '16px',
                            color: 'white',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={handleClose}
                    >
                       <img src={closeIcon} alt="closeIcon" />
                    </Button>
                    {metaData?.other_format && typeof metaData.other_format === 'object' ? (
                        Object.entries(metaData.other_format).map(([type, data]) => (
                            <Button
                                key={type}
                                type="primary"
                                style={{
                                    height: '30px',
                                    width: '100%',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    marginBottom: '10px',
                                    background: 'transparent',
                                    color: '#4A7FC0',
                                    border: '1px solid #4A7FC0',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                }}
                                onClick={() => handleDownloadClick(data)}
                            >
                                <img
                                    alt="example"
                                    src={icDownload}
                                    style={{ marginRight: '10px' }}
                                />
                                {type}
                            </Button>
                        ))
                    ) : (
                        <p style={{ color: 'white', textAlign: 'center', fontSize: '14px' }}>
                            No available formats for download.
                        </p>
                    )}

                </Card>
            </div>
            {/* ))} */}
        </div>
    );
};
