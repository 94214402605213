import maplibregl, { DataDrivenPropertyValueSpecification } from 'maplibre-gl';
import { ExpressionSpecification } from 'maplibre-gl';
import { getStorage, ref, getDownloadURL, getBytes } from 'firebase/storage';
import GridParser from './grid';
import { loadParquet } from '../../queue/parquet';
import * as turf from '@turf/turf';
import { PropertyData } from '../../../../../interface/parquete.interface';
import { filterAllBySeriesName, filterByTimeOfDay } from '../../data/group.layer.series';

export const addGeoJsonLayerParquetReport = async (
    map: maplibregl.Map,
    storageParqueteData: any,
    storageMetaData: any,
    dataSeries: string,
    colorGroup: any,
    setIsLoading?: (isLoading: boolean) => void,
    filter?: string // Tambahkan parameter filter
) => {

    const storage = getStorage();
    const grid_lib = new GridParser();
    const listRef = ref(storage, storageParqueteData);
    const listRefMetaData = ref(storage, storageMetaData);

    try {
        if (setIsLoading) setIsLoading(true); // Start loader
        const downloadUrl = await getDownloadURL(listRef);
        const partData: unknown[] = await loadParquet(downloadUrl);
        const fileBytes = await getBytes(listRefMetaData);
        const textData = new TextDecoder().decode(fileBytes);
        const jsonData = JSON.parse(textData);
        const propertyDataList = partData as PropertyData[];

        
        // Tentukan grup berdasarkan kondisi isSeries
        let groupedData: any;
        let layerGroup = [];
        if (jsonData?.[0]?.isSeries === true) {
            // groupedData = propertyDataList
            groupedData = filterByTimeOfDay(propertyDataList, dataSeries.toLocaleLowerCase());
            layerGroup = filterAllBySeriesName(jsonData, dataSeries);
        } else {
            groupedData = propertyDataList
            layerGroup = jsonData
        }

        // Hapus layer dan source yang ada sebelumnya
        const layers = map.getStyle().layers || [];
        const sources = map.getStyle().sources || {};

        layers.forEach((layer) => {
            if (layer.id.startsWith('layer-')) {
                if (map.getLayer(layer.id)) {
                    map.removeLayer(layer.id);
                }
            }
        });

        Object.keys(sources).forEach((sourceId) => {
            if (sourceId.startsWith('source-')) {
                if (map.getSource(sourceId)) {
                    map.removeSource(sourceId);
                }
            }
        });

        // const allFeatures: any[] = [];
        const BATCH_SIZE = 1000; // Ukuran batch yang sesuai dengan performa perangkat


        // Filter layerGroup untuk hanya menyertakan expenditure_kretek_filter
        const filteredLayerGroup = layerGroup.filter(
            (group: any) => group.columnName === filter
        );

        // Iterasi hanya pada layerGroup yang sudah difilter
        filteredLayerGroup.forEach((group: any) => {
            const sourceId = `source-${group.columnName}`; // Unique source per group
            const layerId = `layer-${group.columnName}`;   // Unique layer per group
            const batchCount = Math.ceil(groupedData.length / BATCH_SIZE);

            // Create a fresh list for features for each group
            const allFeatures: any[] = [];
            for (let batchIndex = 0; batchIndex < batchCount; batchIndex++) {
                const start = batchIndex * BATCH_SIZE;
                const end = start + BATCH_SIZE;

                // Process each batch
                const batchFeatures = groupedData.slice(start, end).map((item: { gid: string, color: string }) => ({
                    type: 'Feature',
                    properties: { ...item },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [grid_lib._gid_to_geometry(item.gid)],  // Use the appropriate geometry transformation
                    },
                }));

                allFeatures.push(...batchFeatures);
            }

            // Tambahkan source untuk setiap grup
            map.addSource(sourceId, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: allFeatures,
                },
            });

            if (allFeatures.length > 0) {
                // Calculate the bounding box and center the map on the data
                const bbox = turf.bbox({
                    type: 'FeatureCollection',
                    features: allFeatures,
                });

                if (bbox.length === 4) {
                    map.fitBounds(bbox, {
                        padding: 20, // Optional: Adjust padding to add space around features
                        maxZoom: 18, // Optional: Set a maximum zoom level to avoid zooming in too much
                    });
                }
            }

            // Warna dan konfigurasi layer
            const { min, max, bins, columnName } = group;
            const isInsightColumn = columnName.split('_').pop()?.toLowerCase() === 'insight';

            const generateRange = (start: number, end: number, length: number): number[] => {
                const step = (end - start) / (length - 1); // Range step
                return Array.from({ length }, (_, i) => start + i * step);
            };

            const rangeInsight = generateRange(0, 1, 10);
            const range = isInsightColumn
                ? Array.from(new Set<number>([min, ...rangeInsight, max])).sort((a, b) => a - b)
                : Array.from(new Set<number>(bins)).sort((a, b) => a - b);

            const colorStops: ExpressionSpecification = [
                'interpolate',
                ['linear'],
                ['get', `${columnName}`],
                ...range.flatMap((value, index) => [
                    value,
                    colorGroup?.color[index] || '#000' // Fallback if color is missing
                ]),
            ];

            // Tambahkan layer
            map.addLayer({
                id: layerId,
                type: 'fill',
                source: sourceId,
                minzoom: 2,
                paint: {
                    'fill-color': colorGroup.type === 'gradient'
                        ? colorStops
                        : colorStops as DataDrivenPropertyValueSpecification<string>,
                    'fill-opacity': 0.8, // Default opacity
                    'fill-outline-color': 'transparent',
                },
            });
        });



        // Fit map bounds ke fitu
    } catch (e) {
    } finally {
        if (setIsLoading) setIsLoading(false); // Stop loader, ensuring it stops even on error
    }
};

