import React, { useState, useEffect } from 'react';
import { Space, Typography } from 'antd';
import { FiEyeOff, FiEye } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { setLayerOpacity } from '../../../component/newmap/helper/map/layer.opacity';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { sortArray } from '../../../component/newmap/helper/short';
import { formatNumberReachKm2,formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';

const { Text } = Typography;

interface DataLayerGroup {
  columnName: string;
  productName: string;
  subdataName: string;
  additinal: any;
  combinedName: string;
  seriesName: string;
  isSeries: boolean;
  bins: number[];
  min: number;
  max: number;
}

interface DrawerProps {
  dataLayerGroup: DataLayerGroup[];
  map: maplibregl.Map;
}

export const DrawerGroupLayer: React.FC<DrawerProps> = ({ dataLayerGroup, map }) => {
  const { colorGroup, setPopUpFilter } = useReduxCatalog();
  const [opacity] = useState<any>(0.8); // Default opacity set to 80%
  const [legendVisibility, setLegendVisibility] = useState<Record<string, boolean>>({});
  // State to handle layer visibility
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});
  // State to store visible layers
  const [visibleLayers, setVisibleLayers] = useState<string[]>([]);

// console.log(dataLayerGroup);

  useEffect(() => {
    const initialVisibility: Record<string, boolean> = {};
    const initialLegendVisibility: Record<string, boolean> = {};
    let defaultLayer: string | null = null;

    dataLayerGroup.forEach((layer, index) => {
      const isDefault = index === 0; // Jadikan layer pertama default terbuka
      initialVisibility[layer.columnName] = isDefault; // Default visible hanya untuk layer pertama
      initialLegendVisibility[layer.columnName] = false;

      // Set opacity ke 1 untuk layer pertama, 0 untuk lainnya
      setLayerOpacity(map, 'layer', layer.columnName, isDefault ? 0.8 : 0);

      if (isDefault) {
        defaultLayer = layer.columnName;
      }
    });

    setVisibility(initialVisibility);
    setLegendVisibility(initialLegendVisibility);

    // Initialize visible layers dengan layer default
    if (defaultLayer) {
      setVisibleLayers([defaultLayer]);
    }
  }, [dataLayerGroup, map]);
  // console.log(legendVisibility);


  // const toggleVisibility = (item: DataLayerGroup) => {
  //   const newVisibility = { ...visibility, [item.columnName]: !visibility[item.columnName] };
  //   setVisibility(newVisibility);

  //   const layerOpacity = newVisibility[item.columnName] ? opacity : 0;
  //   setLayerOpacity(map, 'layer', item.columnName, layerOpacity);
  //   // console.log(layerOpacity);

  //   // Update the visibleLayers array based on the visibility state
  //   if (newVisibility[item.columnName]) {
  //     setVisibleLayers(prevState => [...prevState, item.columnName]); // Add to visible layers if true
  //   } else {
  //     setVisibleLayers(prevState => prevState.filter(layer => layer !== item.columnName)); // Remove if false
  //   }
  // };
  const toggleVisibility = (item: DataLayerGroup) => {
    const newVisibility: Record<string, boolean> = {};
    
    // Hanya layer yang diklik yang akan diaktifkan
    dataLayerGroup.forEach((layer) => {
      newVisibility[layer.columnName] = layer.columnName === item.columnName;
      
      // Atur opacity: layer aktif -> 0.8, lainnya -> 0
      setLayerOpacity(map, 'layer', layer.columnName, layer.columnName === item.columnName ? opacity : 0);
    });
  
    // Perbarui visibility state
    setVisibility(newVisibility);
  
    // Perbarui visibleLayers dengan hanya layer yang aktif
    setVisibleLayers([item.columnName]);
  };
  

  const toggleLegendVisibility = (item: DataLayerGroup) => {
    const newLegendVisibility = { ...legendVisibility, [item.columnName]: !legendVisibility[item.columnName] };
    setLegendVisibility(newLegendVisibility);
  };
  useEffect(() => {
    setPopUpFilter(visibleLayers)
    // eslint-disable-next-line
  }, [visibleLayers])

  return (
    <Space
      direction="vertical"
      style={{
        position: 'absolute',
        right: '10px',
        top: 225,
        width: '235px',
        zIndex: 100,
        transition: '0.25s ease-in-out',
      }}
    >
      <div
        style={{
          background: '#141617',
          border: '1px solid #d9d9d9',
          color: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          width: 'auto',
          height: 'auto',
          maxHeight: '380px',
          overflowY: 'scroll', // Enable vertical scrolling
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none', // For Internet Explorer and Edge
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'sticky', top: 0, background: '#141617', padding: '8px', zIndex: 101 }}>
          <Text style={{ width: '165px', color: 'white', fontWeight: 'bolder' }}>Sub Data</Text>
        </div>
        {dataLayerGroup
          // ?.filter(layer => layer.columnName.toLowerCase() !== 'summary_all') // Filter out 'summary_all'
          ?.map((layer, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px', marginRight: '12px' }}>
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '3px',
                  background: `linear-gradient(0deg,${colorGroup?.color})`,
                  marginRight: '8px',
                }}
              ></div>
              <Text style={{ width: '150px', color: 'white', fontWeight: 'bold' }}>
                {layer.subdataName || layer.productName}
              </Text>
              <div
                onClick={() => toggleVisibility(layer)}
                style={{ marginLeft: '17px', cursor: 'pointer' }}
              >
                {visibility[layer.columnName] ? <FiEye size={17} /> : <FiEyeOff size={17} />}
              </div>
              {!layer.columnName.toLowerCase().includes("insight") && (
                <div
                  onClick={() => toggleLegendVisibility(layer)}
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                >
                  {legendVisibility[layer.columnName] ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}
                </div>
              )}
            </div>

            {legendVisibility[layer.columnName] && !layer.columnName.toLowerCase().includes("insight") && (
              <div style={{ paddingLeft: '20px', marginTop: '5px', overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                {Array.isArray(layer?.bins) && Array.from(new Set(sortArray(layer?.bins))).map((bin, binIndex, sortedBins) => {
                  const nextBin = sortedBins[binIndex + 1];
                  const isLastBin = nextBin === undefined;

                  let binColor = '';
                  if (colorGroup?.type === 'gradient') {
                    const colors = colorGroup?.color || [];
                    const colorIndex = binIndex < colors.length ? binIndex : colors.length - 1;
                    binColor = colors[colorIndex];
                  } else {
                    const colors = colorGroup?.color || [];
                    const colorIndex = Math.min(binIndex, colors.length - 1);
                    binColor = colors[colorIndex];
                  }
                  return (
                    <div key={binIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <div
                        style={{
                          width: '15px',
                          height: '15px',
                          borderRadius: '5px',
                          backgroundColor: binColor,
                          marginRight: '5px',
                        }}
                      ></div>
                      {/* <Text style={{ color: 'white' }}>
                        {layer.columnName === 'reachability_inbound_sqm' || layer.columnName === 'reachability_outbound_sqm'
                          ? isLastBin
                            ? `> ${formatNumberReachSqm(bin)} km2`
                            : `${formatNumberReachSqm(bin)} km2 - ${formatNumberReachSqm(nextBin)} km2`
                          : isLastBin
                            ? `> ${formatNumberSqm(bin)}`
                            : `${formatNumberSqm(bin)} - ${formatNumberSqm(nextBin)}`}
                      </Text> */}
                      <Text style={{ color: 'white' }}>
                        {layer.columnName === 'reachability_inbound_sqm' || layer.columnName === 'reachability_outbound_sqm'
                          ? isLastBin
                            ? bin > 0
                              ? `> ${formatNumberReachKm2(bin)}`
                              : '> 0'
                            : bin > 0 && nextBin > 0
                              ? `${formatNumberReachKm2(bin)} - ${formatNumberReachKm2(nextBin)}`
                              : bin > 0
                                ? `${formatNumberReachKm2(bin)} - ${formatNumberReachKm2(nextBin)}`
                                : `0 - ${formatNumberReachKm2(nextBin)}`
                          : layer.columnName.includes('expenditure')
                            ? isLastBin
                              ? `> Rp.${formatNumberWithDots(bin)}`
                              : `Rp.${formatNumberWithDots(bin)} - Rp.${formatNumberWithDots(nextBin)}`
                            : isLastBin
                              ? bin > 0
                                ? `> ${formatNumberWithDots(bin)}`
                                : '> 0'
                              : bin > 0 && nextBin > 0
                                ? `${formatNumberWithDots(bin)} - ${formatNumberWithDots(nextBin)}`
                                : bin > 0
                                  ? `${formatNumberWithDots(bin)} - ${formatNumberWithDots(nextBin)}`
                                  : `0 - ${formatNumberWithDots(nextBin)}`}
                      </Text>


                    </div>
                  );
                })}
              </div>
            )}

          </div>
        ))}

      </div>
    </Space>
  );
};
