import React, { useState } from "react";
import { Typography, Row, Col, Card } from 'antd';
import agnostic from "../../../../../asesst/new/img/agnon.svg";
import expert from "../../../../../asesst/new/img/expertBE.svg";
import hight from "../../../../../asesst/new/img/highquality.svg";
import Container from "../../../../../style/styledComponent/Container";
import suistanable from "../../../../../asesst/new/img/suistanable.svg";
import easyIcon from '../../../../../asesst/new/icon/howItWorck-ic.svg';
import { fadein } from "../../../../../style/keyframes";
const { Title, Text } = Typography;


const SectionHowItWorkMobile: React.FC = () => {
    const slides = [
        {
            title: "Sustainable and Trusted Data Sources",
            description:
                "We filter and integrate accurate and sustainable data from government records, satellite imagery, cellular providers, public datasets, etc creating a reliable foundation for insights.",
            image: suistanable,
        },
        {
            title: "Expert-Backed and AI-Enhanced Processing",
            description:
                "We utilize layered spatial distributions and combines expert-based methods with AI-driven predictions to achieve high-resolution, 50x50 meter square insights.",
            image: expert,
        },
        {
            title: "High-Quality Insights through Partnerships",
            description:
                "We collaborate with SMEs to deliver thematic insights across demographics, traffic, reachability, and more, enhancing each square’s depth.",
            image: hight,
        },
        {
            title: "Platform-Agnostic and Accessible",
            description:
                "Seamlessly integrates with all GIS and BI platforms. For users without these tools, we offer a simple, intuitive interface to visualize the data.",
            image: agnostic,
        },
    ];
    const headerStyles: React.CSSProperties = {
        padding: '50px 0',
        textAlign: 'left',
        width: '100%',
    };
    const integrationText: React.CSSProperties = {
        alignItems: 'left',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        textAlign:  'left',
    };
    const titleStyle: React.CSSProperties = {
        fontWeight: '800',
        fontSize: '24px',
        color: ' #333333',
        marginTop: '10px',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textAlign: 'left',
    }
    const cardContainerStyles: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: slides.length <= 3 ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px',
        width: '100%',
    };
    const cardStyles: React.CSSProperties = {
        width: '100%',
        position: 'relative',
        minWidth: '350px',
    };



    const [activeIndex,] = useState(0);
    const [, setIsLoading] = useState(true);
    const [, setImageClass] = useState("");

    React.useEffect(() => {
        // Reset loading state and set fade-in class
        setIsLoading(true);
        setImageClass("fade-in");
    
        const timeout = setTimeout(() => {
            setImageClass(""); // Hapus kelas setelah animasi selesai
        }, 600); // Waktu ini harus sesuai dengan durasi animasi fade-in
    
        return () => clearTimeout(timeout);
    }, [activeIndex]);
    

    return (
        <Container
            dir={"column"}
            css={{
                position: "relative",
                width: "100%",
                height: "auto",
                padding:  '10px 5%',
                backgroundSize: "cover",
                backgroundColor: "#fff",
                paddingBottom: "100px",
                // display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
       
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,

                display: 'grid',
                gridTemplateColumns: slides.length <= 3 ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(auto-fit, minmax(300px, 1fr))',
                gap: '20px',
           

            }}
        >
            <div style={headerStyles}>
                <Row justify="start" align="middle">
                    <Col xs={24} md={22}>
                        <div style={integrationText}>
                            <img alt="example" src={easyIcon} style={{
                                marginRight: '10px',
                                marginBottom: '0',
                                color: '#316DBA',
                            }} />
                            <p style={{
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                color: '#316DBA', fontSize: '24px', fontWeight: 'bolder'
                            }}>Our Process</p>
                        </div>
                        <Title style={titleStyle}>How it Works</Title>
                        <Text style={{
                            fontFamily: "'Plus Jakarta Sans', sans-serif",
                            fontSize: '16px', color: '#595959', display: 'block',
                            fontWeight: '400', lineHeight: '1.2', textAlign: 'left'
                        }}>
                            We have simplified the discovery process into just three steps, making it easy for you to find the right solution.
                        </Text>
                    </Col>
                </Row>
            </div>
            <div style={cardContainerStyles}>
                    {slides.map((service, index) => (
                        <Card key={index} style={cardStyles} bordered={false}
                            cover={<img alt="example" src={service.image} height={300} style={{ borderRadius: '0px' }} />}
                        >
                            <Title style={{ fontFamily: '"Jakarta Sans", sans-serif', fontSize:'24px', fontWeight:700}} >{service.title}</Title>
                            <Text style={{ fontFamily: '"Jakarta Sans", sans-serif',}}>{service.description}</Text>
                        </Card>
                    ))}
                </div>
        </Container>
    );
};


export default SectionHowItWorkMobile;
