import React, { useEffect, useState } from 'react';
import { Typography, Button } from 'antd';
import { CSSProperties } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import bgImage from '../../../../asesst/Icons/illust.png';
import IconSearch from '../../../../asesst/new/icon/mingcute_search-line.svg';

import IconScoring from '../../../../asesst/new/icon/poi-location-scoring.svg';
import IconTraffic from '../../../../asesst/new/icon/poi-trafic.svg';
import IconProfiling from '../../../../asesst/new/icon/poi-location-profile.svg';

import IconScoringMap from '../../../../asesst/new/icon/scorring.svg';
import IconTrafficMap from '../../../../asesst/new/icon/traffic.svg';
import IconProfilingMap from '../../../../asesst/new/icon/location.svg';

import circleScoring from '../../../../asesst/new/icon/circle-scoring.svg';
import circleTraffic from '../../../../asesst/new/icon/cirlec-traffic.svg';
import circleProfiling from '../../../../asesst/new/icon/circle-profile.svg';

import gbScoring from '../../../../asesst/new/img/gb-locationscoring.svg';
import lvScoring from '../../../../asesst/new/img/gb-landvalue.svg';

import reachProfile from '../../../../asesst/new/img/reachability.png';
import summaryProfile from '../../../../asesst/new/img/summary.png';
import detailProfile from '../../../../asesst/new/img/detail.png';

import bygenerationTraffic from '../../../../asesst/new/img/bygen.png';
import byGenTraffic from '../../../../asesst/new/img/bygender.png';
import byHobTraffic from '../../../../asesst/new/img/byhobby.png';

import { FaMapMarkerAlt, FaRoad, FaVectorSquare } from 'react-icons/fa';  // Import ikon dari react-icons
import { useNavigate } from 'react-router-dom'; // Tambahkan ini untuk navigasi

const { Title, Text } = Typography;

const SectionHeroNew = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentText, setCurrentText] = useState('Data');
    const textOptions = ['Data', 'Insights', 'Profile', 'Score'];
    const [activeTabScoring, setActiveTabScoring] = useState('General Business');
    const [activeTabProfilling, setActiveTabProfilling] = useState('Reachability');
    const [activeTabTraffic, setActiveTabTraffic] = useState('By Gender');
    const [activeButton, setActiveButton] = useState<number | null>(1); // Allow both number and null
    const [query, setQuery] = useState(""); // State untuk input pencarian
    const [results, setResults] = useState([]); // State untuk hasil pencarian
    const [, setLoading] = useState(false); // State untuk loader
    const [debounceQuery, setDebounceQuery] = useState(""); // State untuk debounce

    const navigate = useNavigate(); // Inisialisasi useNavigate


    // Debounce Effect
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebounceQuery(query); // Set debounceQuery setelah 500ms
        }, 500);

        return () => clearTimeout(timer); // Bersihkan timer jika query berubah
    }, [query]);

    // Fetch data ketika debounceQuery berubah
    useEffect(() => {
        const fetchLocations = async () => {
            if (!debounceQuery) {
                setResults([]); // Kosongkan hasil jika query kosong
                return;
            }

            setLoading(true);
            try {
                const response = await fetch(
                    `https://api.maptiler.com/geocoding/${encodeURIComponent(debounceQuery)}.json?key=IkttUJmMfqWCx0g43vGM`
                );
                const data = await response.json();
                setResults(data.features || []); // Simpan hasil pencarian
            } catch (error) {
                console.error("Error fetching geocoding results:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLocations();
    }, [debounceQuery]);
    // Data for buttons with their positions and images
    const buttons = [
        {
            id: 1,
            name: 'scoring',
            position: { left: '63%', top: '57%' },
            defaultImage: circleScoring,
            activeImage: IconScoringMap,
        },
        {
            id: 2,
            name: 'trafic',
            position: { left: '67%', top: '31%' },
            defaultImage: circleTraffic,
            activeImage: IconTrafficMap,
        },
        {
            id: 3,
            name: 'profiling',
            position: {
                left: '56%',
                top: '40%'
            },
            defaultImage: circleProfiling,
            activeImage: IconProfilingMap,
        },
    ];


    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        let index = 0;
        const interval = setInterval(() => {
            index = (index + 1) % textOptions.length; // Cycle through the array
            setCurrentText(textOptions[index]);
        }, 1800); // 2 seconds
        return () => clearInterval(interval); // Cleanup interval on unmount
        // eslint-disable-next-line 
    }, []);
    const handleLocationSelect = (location: any) => {
        // Store the selected location in localStorage
        localStorage.setItem('selectedLocation', JSON.stringify(location));
        // Navigate to the map page
        navigate('/map');
    };


    const handleTabClickScoring = (params: string) => {
        setActiveTabScoring(params);
    };
    const handleTabClickProfilling = (params: string) => {
        setActiveTabProfilling(params);
    };
    const handleTabClickTraffic = (params: string) => {
        setActiveTabTraffic(params);
    };
    const renderTabContentScoring = () => {
        if (activeTabScoring === 'General Business') {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={gbScoring} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            );
        } else {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={lvScoring} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            )
        }
    };
    const renderTabContentProfilling = () => {
        if (activeTabProfilling === 'Reachability') {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={reachProfile} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            );
        } else if (activeTabProfilling === 'Summary') {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={summaryProfile} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            )
        } else {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={detailProfile} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            )
        }
    };
    const renderTabContentTraffic = () => {
        if (activeTabTraffic === 'By Gender') {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={byGenTraffic} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            );
        } else if (activeTabTraffic === 'By Generation') {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={bygenerationTraffic} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            )
        } else {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={byHobTraffic} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            )
        }
    };
    const buttonStyles = (isActive: boolean): React.CSSProperties => ({
        position: 'absolute', // Explicitly typed
        zIndex: 100,
        cursor: isActive ? 'pointer' : 'pointer',
        transition: 'all 0.3s ease-in-out',
        transform: 'translate(-50%, -50%)', // Center align the button
    });

    // Icon styles
    const iconStyles: React.CSSProperties = {
        width: '48px',
        height: '48px',
        objectFit: 'cover',
    };
    const iconStylesActive: React.CSSProperties = {
        width: '55px',
        height: '55px',
        objectFit: 'cover',
    };
    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: 'flex',
            alignItems: 'center',
            margin: '10px',
            justifyContent: 'left',
            flexDirection: 'row',
            textAlign: 'left'

        },
        icon: {
            marginRight: '10px',
            marginBottom: '0',
            color: '#316DBA',
        },
        integrationText: {
            color: '#333939',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: 'left'
        },
        title: {
            fontFamily: '"Jakarta Sans", sans-serif',
            // marginRight: windowWidth <= 768 ? '0%' : '65%',
            fontWeight: '800',
            fontSize: '60px',
            color: 'white',
            marginTop: '-10px',
            marginBottom: '0px',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        titleBlueAnimation: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 800,
            fontSize: '60px',
            color: '#316DBA',
            textDecorationLine: 'underline', // Add underline to the text
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        titleBlue: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 800,
            fontSize: '60px',
            color: '#316DBA',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        description: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            marginTop:'10px',
            fontSize: '22px',
            fontWeight: '400',
            color: '#F6F8FA',
            display: 'block',
            width: '100%',
            lineHeight: '1.4',
            // marginTop: '10px',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        button: {
            width: '175px',
            height: '44px',
            marginTop: '20px',
            backgroundColor: '#316DBA',
            borderColor: '#316DBA',
            color: 'white',
        },
        mapContainer: {
            position: 'relative',
        },
        mapImage: {
            width: '100%',
            height: 'auto',
        },
        floatingCardStyles: {
            width: '320px',
            height: '384px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            position: 'absolute',
            top: '19%',
            right: '7%',
        }
    };

    return (
        <Container
            dir={"column"}
            css={{
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,
                width: "100%",
                padding: "1rem 10rem", // Adjust padding for small vs large screens
                backgroundColor: "#00152F",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: windowWidth <= 768 ? "cover" : "contain", // Cover for smaller screens, contain for larger
                backgroundPosition: "center", // Center align for better aesthetics
                backgroundRepeat: "no-repeat", // Prevent repeating background
                minHeight: "90vh", // Ensure the background stretches to full viewport height

            }}
        >
            <div >
                {buttons.map((button, index) => (
                    <div
                        key={button.id}
                        id={String(button.id)}
                        style={{ 
                            ...buttonStyles(activeButton === button.id), 
                            ...button.position,
                        }}
                        onClick={() => {
                            if (activeButton !== button.id) setActiveButton(button.id); // Update state only if not active
                        }}
                    >
                        {activeButton === button.id ?
                            <img
                                alt={`Icon ${button.id}`}
                                src={button.activeImage}
                                style={iconStylesActive}
                      
                            /> :
                            <img
                                alt={`Icon ${button.id}`}
                                src={button.defaultImage}
                                style={iconStyles}
                            />
                        }
                    </div>
                ))}
            </div>
            <div style={{ height: '80vh', marginTop: '10px', marginBottom: '30px' }}>
                <Container css={{ padding: '50px 0' }}>
                    <Container
                        dir={"column"}
                        css={{
                            width: "100%",
                            height: "100%",
                            marginTop: '0%',
                            opacity: "0",
                            padding: '50px 0',
                            animation: `1s ease-in 1s 1 both ${fadein}`,
                            alignItems: 'flex-start',
                        }}
                    >
                        <Title style={styles.title}>The Right <Text style={styles.titleBlueAnimation}>{currentText}</Text></Title>
                        <Title style={{...styles.title, paddingTop:'10px'}}>at The Right Scale</Title>
                        <Title style={styles.title}>For Every <Text style={styles.titleBlue}>Location!</Text></Title>
                        <Text style={styles.description}>
                            Get precise location insights:
                            <br></br>
                            just what you need, exactly where you need it.
                        </Text>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#fff',
                            borderRadius: '10px',
                            border: '1px solid #ccc',
                            width: '100%',
                            height: '60px',
                            maxWidth: '50%',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                            marginTop: '20px',
                            overflow: 'hidden' // memastikan elemen tetap dalam radius border
                        }}>
                            {/* Icon Search */}
                            <div
                                style={{
                                    backgroundColor: '#316DBA',
                                    width: '60px',
                                    height: '60px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                 <img alt="example" src={IconSearch}  />
                            </div>

                            {/* Input Field */}
                            <input
                                type="text"
                                placeholder="Search location (e.g., Cilandak)"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '100%',
                                    fontSize: '16px',
                                    color: '#333',
                                    padding: '5px 15px' // Memberikan jarak antar teks dengan tepi
                                }}
                            />
                        </div>
                        {/* {loading && <p style={{ marginTop: "10px" }}>Loading...</p>} */}

                        {/* Results */}
                        {results.length > 0 && (
                            <ul
                                style={{
                                    marginTop: "1px",
                                    listStyleType: "none",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    maxHeight: "300px",
                                    maxWidth:'50%',
                                    minWidth:'50%',
                                    overflowY: "auto",
                                }}
                            >

                                {results.length > 0 && (
                                    <ul
                                        style={{
                                            marginTop: "10px",
                                            listStyleType: "none",
                                            borderRadius: "10px",
                                            backgroundColor: "#fff",
                                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                            maxHeight: "140px",
                                            overflowY: "auto",
                                        }}
                                    >
                                        {results.map((result: any, index: number) => (
                                            <li
                                                key={index}
                                                style={{
                                                    padding: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    backgroundColor: "#f9f9f9",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    marginBottom: "5px",
                                                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                                                    fontSize: "14px",
                                                    transition: "background-color 0.3s ease",
                                                }}
                                                onClick={() => handleLocationSelect(result)} // Select location and navigate
                                                onMouseEnter={(e) =>
                                                    (e.currentTarget.style.backgroundColor = "#e0e0e0")
                                                }
                                                onMouseLeave={(e) =>
                                                    (e.currentTarget.style.backgroundColor = "#f9f9f9")
                                                }
                                            >
                                                {/* Icon berdasarkan tipe */}
                                                <div
                                                    style={{
                                                        marginRight: "10px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {result.geometry.type === "Polygon" && (
                                                        <FaVectorSquare size={20} color="#217aff" />
                                                    )}
                                                    {result.geometry.type === "Line" && (
                                                        <FaRoad size={20} color="#217aff" />
                                                    )}
                                                    {result.geometry.type === "Point" && (
                                                        <FaMapMarkerAlt size={20} color="#217aff" />
                                                    )}
                                                </div>
                                                {/* Nama lokasi */}
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    {result.place_name}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </ul>
                        )}


                    </Container>
                </Container>
            </div>
            <div style={styles.floatingCardStyles}>
                <div style={{
                    padding: '10px'
                }}>
                    <div style={styles.integrationHeader}>
                        {activeButton === 1 && (
                            <>
                                <img alt="example" src={IconScoring} style={styles.icon} /><Text style={styles.integrationText}>Location Scoring</Text>
                            </>
                        )}
                        {activeButton === 2 && (
                            <>
                                <img alt="example" src={IconTraffic} style={styles.icon} /><Text style={styles.integrationText}>People Traffic</Text>
                            </>
                        )}

                        {activeButton === 3 && (
                            <>
                                <img alt="example" src={IconProfiling} style={styles.icon} /><Text style={styles.integrationText}>Location Profile</Text>
                            </>
                        )}


                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        padding: '10px',
                        alignItems: 'center',
                        height: '40px',
                        backgroundColor: '#DCE1E7',
                        borderRadius: '6px',
                        margin: '0 auto',
                    }}>
                        {activeButton === 1 && ['General Business', 'Land Value'].map((tab) => (
                            <Button
                                type="text"
                                key={tab}
                                id={tab}
                                onClick={() => handleTabClickScoring(tab)}
                                style={{
                                    height: '32px',
                                    width: windowWidth <= 768 ? '100px' : '200px',
                                    borderRadius: '6px',
                                    color: activeTabScoring === tab ? '#333939' : '#7D8587',
                                    backgroundColor: activeTabScoring === tab ? '#F6F8FA' : 'transparent',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    padding: '0 10px',
                                    border: activeTabScoring === tab ? '1px solid #F6F8FA' : '1px solid transparent',
                                }}
                            >
                                {tab}
                            </Button>
                        ))}
                        {activeButton === 2 && ['By Gender', 'By Generation', 'By Hobby'].map((tab) => (
                            <Button
                                type="text"
                                key={tab}
                                id={tab}
                                onClick={() => handleTabClickTraffic(tab)}
                                style={{
                                    height: '32px',
                                    width: windowWidth <= 768 ? '100px' : '200px',
                                    borderRadius: '6px',
                                    color: activeTabTraffic === tab ? '#333939' : '#7D8587',
                                    backgroundColor: activeTabTraffic === tab ? '#F6F8FA' : 'transparent',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    padding: '0 10px',
                                    border: activeTabTraffic === tab ? '1px solid #F6F8FA' : '1px solid transparent',
                                }}
                            >
                                {tab}
                            </Button>
                        ))}
                        {activeButton === 3 && ['Reachability', 'Summary', 'Detail'].map((tab) => (
                            <Button
                                type="text"
                                key={tab}
                                id={tab}
                                onClick={() => handleTabClickProfilling(tab)}
                                style={{
                                    height: '32px',
                                    width: windowWidth <= 768 ? '100px' : '200px',
                                    borderRadius: '6px',
                                    color: activeTabProfilling === tab ? '#333939' : '#7D8587',
                                    backgroundColor: activeTabProfilling === tab ? '#F6F8FA' : 'transparent',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    padding: '0 10px',
                                    border: activeTabProfilling === tab ? '1px solid #F6F8FA' : '1px solid transparent',
                                }}
                            >
                                {tab}
                            </Button>
                        ))}
                    </div>
                    <div style={{ overflowY: 'auto' }}>
                        {activeButton === 1 && (
                            <>
                                {renderTabContentScoring()}
                            </>
                        )}
                        {activeButton === 3 && (
                            <>
                                {renderTabContentProfilling()}
                            </>
                        )}
                        {activeButton === 2 && (
                            <>
                                {renderTabContentTraffic()}
                            </>
                        )}
                    </div>
                </div>

            </div>
        </Container>
    );
};

export default SectionHeroNew;
