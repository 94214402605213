import React from 'react';
import { Space, Button } from 'antd';
import settingIcon from '../../../asesst/Icons/interactivemap/icon/setting-tools.svg';
import closeIcon from '../../../asesst/Icons/interactivemap/icon/close-tools.svg';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';

export const ShowHideTools: React.FC<any> = () => {
  const { setShowRightDrawer, showRightDrawer } = useReduxCatalog();

  return (
    <React.Fragment>
      <Space
        direction="vertical"
        style={{
          position: 'absolute',
          right: '10px',
          top: 20,
          zIndex: 100,
          transition: '0.25s ease-in-out',
        }}
      >
        {showRightDrawer ? (
          <Button
            id="close-drawer"
            onClick={() => setShowRightDrawer(false)} // Tutup drawer
            style={{
              backgroundColor: '#141617',
              color: 'black',
              height: '50px',
              width: '50px',
              borderRadius: '12px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={closeIcon} alt="closeIcon" />
          </Button>
        ) : (
          <Button
            id="open-drawer"
            onClick={() => setShowRightDrawer(true)} // Buka drawer
            style={{
              backgroundColor: '#141617',
              color: 'black',
              height: '50px',
              width: '50px',
              borderRadius: '12px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={settingIcon} alt="settingIcon" />
          </Button>
        )}
      </Space>
    </React.Fragment>
  );
};
