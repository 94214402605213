import maplibregl from 'maplibre-gl';
import { removePopupIsochrones } from './isochrones';
import { removeCurrentMarker } from './draw.point';
import { removeDrawControl } from './draw.polygon';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { clearGeocodeLayer } from './geocode';

/**
 * Removes 'geocode-result' and 'geocode-layer' layers, their sources, and any existing popups from the map.
 * @param map - The MapLibre GL map instance.
 */
export const removeGeocodeLayersAndPopups = (map: maplibregl.Map) => {
    clearGeocodeLayer(map)
    // Remove any existing popups
    const existingPopups = document.getElementsByClassName('mapboxgl-popup');
    while (existingPopups.length) {
        existingPopups[0].remove();
    }
};


export const removeIsochronesAndPopups = (map: maplibregl.Map) => {
    // Remove 'geocode-result' and 'geocode-layer' layers if they exist
    if (map.getLayer('isochrone-source')) {
        map.removeLayer('isochrone-source');
    }
    if (map.getLayer('isochrone-layer')) {
        map.removeLayer('isochrone-layer');
    }

    // Remove the sources for these layers if they exist
    if (map.getSource('isochrone-source')) {
        map.removeSource('isochrone-source');
    }
    if (map.getSource('isochrone-layer')) {
        map.removeSource('isochrone-layer');
    }
    removePopupIsochrones()
    removeCurrentMarker();

};


export const clearPolygonLayer = (map: maplibregl.Map, draw: MapboxDraw) => {
    if (map && draw) {
        removeDrawControl(map, draw);
    }
};

// src/component/newmap/helper/map/sourceLayer.ts

export const removeLayersAndSources = (map: maplibregl.Map, layerPrefix = 'layer-', sourcePrefix = 'source-') => {
    if (!map) return;

    // Remove layers with the specified prefix
    const layers = map.getStyle().layers || [];
    layers.forEach((layer) => {
        if (layer.id.startsWith(layerPrefix) && map.getLayer(layer.id)) {
            map.removeLayer(layer.id);
        }
    });

    // Remove sources with the specified prefix
    const sources = map.getStyle().sources || {};
    Object.keys(sources).forEach((sourceId) => {
        if (sourceId.startsWith(sourcePrefix) && map.getSource(sourceId)) {
            map.removeSource(sourceId);
        }
    });
};


export const removeLayerTop5 = (map: maplibregl.Map) => {
    // Remove 'geocode-result' and 'geocode-layer' layers if they exist
    if (map.getLayer('highlighted-grid-source-outline')) {
        map.removeLayer('highlighted-grid-source-outline');
    }
    if (map.getLayer('highlighted-grid-layer-outline')) {
        map.removeLayer('highlighted-grid-layer-outline');
    }

    // Remove the sources for these layers if they exist
    if (map.getSource('highlighted-grid-source-outline')) {
        map.removeSource('highlighted-grid-source-outline');
    }
    if (map.getSource('highlighted-grid-layer-outline')) {
        map.removeSource('highlighted-grid-layer-outline');
    }

    if (map.getLayer('highlighted-grid-source')) {
        map.removeLayer('highlighted-grid-source');
    }
    if (map.getLayer('highlighted-grid-layer')) {
        map.removeLayer('highlighted-grid-layer');
    }

    // Remove the sources for these layers if they exist
    if (map.getSource('highlighted-grid-source')) {
        map.removeSource('highlighted-grid-source');
    }
    if (map.getSource('highlighted-grid-layer')) {
        map.removeSource('highlighted-grid-layer');
    }
};
