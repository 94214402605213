import React from 'react';
import { MapProvider } from 'react-map-gl/maplibre';
import { XMapLayoutComponentLayerLoader } from '../component/componentLayerLoader';
import { MapPoint } from '../component/componentMap/mapPoint';

interface XMapPointProps {
  children?: React.ReactNode;
  filter?: string | undefined ; // Opsional, diteruskan ke MapPoint jika diperlukan
}

export const XMapPoint: React.FC<XMapPointProps> = ({ children, filter }) => {
  return (
    <MapProvider>
      <MapPoint filter={filter}>
        <XMapLayoutComponentLayerLoader>
          {children}
        </XMapLayoutComponentLayerLoader>
      </MapPoint>
    </MapProvider>
  );
};
