import React, { useEffect, useState } from 'react';
import { Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import customSection from "../../../../asesst/new/public-access.svg";
import dataIcon from '../../../../asesst/new/icon/icon-park-outline_data-user.svg';
import listPublicAssetImg from '../../../../asesst/new/img/public-dataset.svg';
import { CSSProperties } from 'react';

const { Title, Text } = Typography;

const SectionDataPublic = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        navigate('/map');
    };

    const floatingCardStyles: React.CSSProperties = {
        position: 'absolute',
        left:'45%',
        top: '20%'
        // bottom: windowWidth <= 768 ? '100px' : '0px',
        // right: windowWidth <= 768 ? '5%' : '6%',
    };

    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
            flexDirection: windowWidth <= 768 ? 'column' : 'row',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        icon: {
            marginRight: windowWidth <= 768 ? '' : '10px',
            marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#316DBA',
        },
        integrationText: {
            color: '#316DBA',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        title: {
            fontWeight: '800',  
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#333333',
            marginTop: '10px',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        description: {
            fontSize: '16px',
            color: '#333333',
            display: 'block',
            width: windowWidth <= 768 ? '100%' : '500px',
            lineHeight: '1.5',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        button: {
            width: '175px',
            height: '44px',
            marginTop: '20px',
            backgroundColor: '#316DBA',
            borderColor: '#316DBA',
            color: 'white',
            alignSelf: windowWidth <= 768 ? 'center' : 'flex-start',
        },
        mapContainer: {
            position: 'relative',
        },
        mapImage: {
            width: '100%',
            height: 'auto',
        },
        responsiveImage: {
            width: windowWidth <= 768 ? '100%' : 'auto',
            height: windowWidth <= 768 ? 'auto' : 'auto',
            marginTop: windowWidth <= 768 ? '20px' : '0',
        },
    };

    return (
        <Container
            css={{
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,
                width: "100%",
                padding: "1rem 10rem", // Adjust padding for small vs large screens
                backgroundColor: 'white',
                display: "grid",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                cursor: "pointer",
                gridTemplateColumns: "1.5fr 1.5fr",
                '@media (max-width: 768px)': {
                    gridTemplateColumns: "1fr",
                    padding: "20px",
                }
            }}
        >
            <Container css={{ padding: '50px 0' }}>
                <Container
                    dir={"column"}
                    css={{
                        width: "100%",
                        height: "100%",
                        opacity: "0",
                        padding: '50px 0',
                        animation: `1s ease-in 1s 1 both ${fadein}`,
                        display: 'flex',
                        alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
                    }}
                >
                    <div style={styles.integrationHeader}>
                        <img alt="example" src={dataIcon} style={styles.icon} />
                        <Text style={styles.integrationText}>Public Data</Text>
                    </div>

                    <Title style={styles.title}>Free datasets for you!</Title>
                    <Text style={styles.description}>
                        Explore, analyze, and innovate without any cost, and make the most of high quality open data available on your fingertips.
                    </Text>

                    <Button onClick={handleClick} style={styles.button}>
                        <span style={{ color: 'white', fontSize: '14px', fontWeight: 'bolder',  fontFamily: "'Plus Jakarta Sans', sans-serif", }}>Access it here!</span>
                    </Button>
                </Container>
            </Container>
            <Container css={{ padding: '50px 0',  justifyContent:'center' }}>
                <img
                    src={customSection}
                    alt="Animated GIF"
                    className="responsive-image"
                    style={styles.responsiveImage}
                />
            </Container>
            <div style={floatingCardStyles}>
                <img src={listPublicAssetImg} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
        </Container>
    );
};

export default SectionDataPublic;
