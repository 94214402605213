import React, { useEffect, useState } from 'react';
import { Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Container from "../../../../../style/styledComponent/Container";
import { fadein } from "../../../../../style/keyframes";
import customSection from "../../../../../asesst/new/mobile/api one.svg";
import apiIcon from '../../../../../asesst/new/icon/openApi.svg';
import dashboardImg from '../../../../../asesst/new/mobile/dashboardMobileOneApi.svg';
import { CSSProperties } from 'react';

const { Title, Text } = Typography;

const SectionApiCallMobile = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        navigate('/api-intergration');
    };

    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            alignItems: 'left',
            justifyContent: 'flex-start',
            flexDirection:  'column',
            paddingTop:'50px',
            textAlign:'left',
        },
        icon: {
            marginRight: windowWidth <= 768 ? '' : '10px',
            marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#316DBA',
        },
        integrationText: {
            color: '#316DBA',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: 'left',
        },
        title: {
            fontWeight: '800',  
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#ffffff',
            marginTop: '10px',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: 'left',
        },
        description: {
            fontSize: '16px',
            color: '#ffffff',
            display: 'block',
            width: windowWidth <= 768 ? '100%' : '500px',
            lineHeight: '1.5',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign:  'left',
        },
        button: {
            width: '175px',
            height: '44px',
            marginTop: '10px',
            backgroundColor: '#316DBA',
            borderColor: '#316DBA',
            color: 'white',
            alignSelf: 'flex-start',
        },
        mapContainer: {
            position: 'relative',
        },
        mapImage: {
            width: '100%',
            height: 'auto',
        },
        responsiveImage: {
            height: windowWidth <= 768 ? 'auto' : '751px',
            width: windowWidth <= 768 ? '100%' : '689px',
            marginTop: windowWidth <= 768 ? '20px' : '0',
        },
    };

    return (
        <>
            <Container
                css={{
                    margin: '0px',
                    opacity: "0",
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                    width: "100%",
                    padding: " $2 $10",
                    backgroundColor: '#00152F',
                    display: "grid",
                    height: "auto",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    gridTemplateColumns: "1fr 1fr",
                    '@media (max-width: 768px)': {
                        gridTemplateColumns: "1fr", // Adjust to one column in mobile view
                        padding: "20px",
                    }
                }}
            >
                <Container>
                    <Container
                        dir={"column"}
                        css={{
                            width: "100%",
                            height: "100%",
                            opacity: "0",
                            animation: `1s ease-in 1s 1 both ${fadein}`,
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div style={styles.integrationHeader}>
                            <img alt="example" src={apiIcon} style={styles.icon} />
                        </div>
                        <Text style={styles.integrationText}>Integration</Text>
                        <Title style={styles.title}>One API call away!</Title>
                        <Text style={styles.description}>
                            Effortlessly integrate our comprehensive geospatial insights, data, and services into your system with just a single API call!
                        </Text>

                        <Button onClick={handleClick} style={styles.button}>
                            <span style={{ color: 'white', fontSize: '14px', fontWeight: '700px', fontFamily: '"Jakarta Sans", sans-serif' }}>Integrate with us!</span>
                        </Button>
                    </Container>
                </Container>
                <Container css={{objectFit:'none'}}>
                    <img
                        src={customSection}
                        alt="Animated GIF"
                      style={{ width: '100%', height: '100%', objectFit: 'none' }}/>
                      
                </Container>
            </Container>
            <Container
                css={{
                    // margin: '0px',
                    // paddingLeft: windowWidth <= 768 ? '20px' : '55px',
                    // paddingRight: windowWidth <= 768 ? '20px' : '55px',
                    opacity: "0",
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                    width: "100%",
                    backgroundColor: '#00152F',
                }}
            >
                <img src={dashboardImg} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Container>
        </>
    );
};


export default SectionApiCallMobile;
