/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import { getEsriWorldImageryStyle, getLightMapStyle, getOSMMapStyle } from '../../../component/newmap/helper/layer/layer.tile';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
interface DrawToolsSpaceProps {
    drawerOpen: boolean;
    onChangeBasemap: (style: maplibregl.StyleSpecification | string) => void;
}

export const CompBaseMaps: React.FC<DrawToolsSpaceProps> = ({ drawerOpen, onChangeBasemap }) => {
    const {
        dataLayerGroup,
      } = useReduxCatalog();
    const handleBasemapChange = (basemapType: string) => {
        switch (basemapType) {
            case 'light':
                onChangeBasemap(getLightMapStyle());
                break;
            case 'satellite':
                onChangeBasemap(getEsriWorldImageryStyle());
                break;
            case 'OSM':
                onChangeBasemap(getOSMMapStyle());
                break;
            default:
                break;
        }
    };
    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: dataLayerGroup?.length > 0 ? '295px' : '60px',
                    top: 20,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip
                    title={<p style={{color:'black'}}>Light</p>}
                    placement="top"
                    color='white'
                >
                    <Button
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            height: "50px",
                            width: "50px",
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        // eslint-disable-next-line jsx-a11y/alt-text
                        icon={
                            <img
                                alt='white'
                                src='https://a.basemaps.cartocdn.com/rastertiles/light_all/4/13/7.png'
                                style={{
                                    width: 45, height: 45, borderRadius: 10
                                }}
                            />
                        }
                        onClick={() => handleBasemapChange('light')}
                    // onClick={() => handleBasemapChange('https://a.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png')}
                    >
                    </Button>
                </Tooltip>
            </Space>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: dataLayerGroup?.length > 0 ? '345px' : '95px',
                    top: 20,
                    zIndex: 100,
                    transition: '0.50s ease-in-out',
                }}
            >
                <Tooltip
                  title={<p style={{color:'black'}}>OSM</p>}
                  placement="top"
                  color='white'
                >
                    <Button

                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            height: "50px",
                            width: "50px",
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        icon={
                            <img
                                alt='OSM'
                                src='https://a.tile.openstreetmap.org/5/25/16.png'
                                style={{
                                    width: 45, height: 45, borderRadius: 10
                                }} />
                        }
                        onClick={() => handleBasemapChange('OSM')}
                    >
                    </Button>
                </Tooltip>
            </Space>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: drawerOpen ? '395px' : '95px',
                    top: 20,
                    zIndex: 100,
                    transition: '0.80s ease-in-out',
                }}
            >
                <Tooltip
                     title={<p style={{color:'black'}}>Satelite</p>}
                     placement="top"
                     color='white'
                >
                    <Button
 
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            height: "50px",
                            width: "50px",
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        icon={
                            <img
                                alt='Satelite'
                                src='https://mt0.google.com/vt/lyrs=s&x=25&y=16&z=5'
                                style={{
                                    width: 45, height: 45, borderRadius: 10
                                }} />
                        }
                        onClick={() => handleBasemapChange('satellite')}
                    // onClick={() => handleBasemapChange('https://mt0.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')}

                    >
                    </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}