import React, { useState, useEffect } from 'react';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import IconSearch from '../../../../../asesst/new/icon/mingcute_search-line.svg';
import Container from "../../../../../style/styledComponent/Container";
import { CSSProperties } from 'react';

import bgImage from '../../../../../asesst/new/img/bg-image-heromobile.svg';
import IconScoring from '../../../../../asesst/new/icon/poi-trafic.svg';
import { fadein } from '../../../../../style/keyframes';
import bygenerationTraffic from '../../../../../asesst/new/img/bygen.png';
import byGenTraffic from '../../../../../asesst/new/img/bygender.png';
import byHobTraffic from '../../../../../asesst/new/img/byhobby.png';
import { FaMapMarkerAlt, FaRoad, FaVectorSquare } from 'react-icons/fa';  // Import ikon dari react-icons

const { Title, Text } = Typography;

const SectionHeroMobile: React.FC = () => {
    const navigate = useNavigate(); // Inisialisasi useNavigate
    const [currentText, setCurrentText] = useState('Data');
    const textOptions = ['Data', 'Insights', 'Profile', 'Score'];
    const [debounceQuery, setDebounceQuery] = useState(""); // State untuk debounce
    const [activeTabTraffic, setActiveTabTraffic] = useState('By Gender');
    const [query, setQuery] = useState(""); // State untuk input pencarian
    const [results, setResults] = useState([]); // State untuk hasil pencarian
    const [, setLoading] = useState(false); // State untuk loader
    const handleTabClickTraffic = (params: string) => {
        setActiveTabTraffic(params);
    };
    const renderTabContentTraffic = () => {
        if (activeTabTraffic === 'By Gender') {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={byGenTraffic} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            );
        } else if (activeTabTraffic === 'By Generation') {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={bygenerationTraffic} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            )
        } else {
            return (
                <>
                    <div style={{
                        marginTop: '5px'
                    }}>
                        <img src={byHobTraffic} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>

                </>
            )
        }
    };

    useEffect(() => {
        let index = 0;
        const interval = setInterval(() => {
            index = (index + 1) % textOptions.length; // Cycle through the array
            setCurrentText(textOptions[index]);
        }, 1500); // 2 seconds
        return () => clearInterval(interval); // Cleanup interval on unmount
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebounceQuery(query); // Set debounceQuery setelah 500ms
        }, 500);

        return () => clearTimeout(timer); // Bersihkan timer jika query berubah
    }, [query]);

    // Fetch data ketika debounceQuery berubah
    useEffect(() => {
        const fetchLocations = async () => {
            if (!debounceQuery) {
                setResults([]); // Kosongkan hasil jika query kosong
                return;
            }

            setLoading(true);
            try {
                const response = await fetch(
                    `https://api.maptiler.com/geocoding/${encodeURIComponent(debounceQuery)}.json?key=IkttUJmMfqWCx0g43vGM`
                );
                const data = await response.json();
                setResults(data.features || []); // Simpan hasil pencarian
            } catch (error) {
                console.error("Error fetching geocoding results:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLocations();
    }, [debounceQuery]);

    const handleLocationSelect = (location: any) => {
        // Store the selected location in localStorage
        localStorage.setItem('selectedLocation', JSON.stringify(location));
        // Navigate to the map page
        navigate('/map');
    };

    const styles: { [key: string]: CSSProperties } = {
        floatingCardStyles: {
            width: '100%',
            height: '100%',
            borderRadius: '10px',
            marginTop:'10px',
            backgroundColor: '#fff',
        },
        integrationText: {
            color: '#333939',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: 'left'
        },
        icon: {
            marginRight: '10px',
            marginBottom: '0',
            color: '#316DBA',
        },
        title: {
            fontFamily: '"Jakarta Sans", sans-serif',
            fontWeight: '800',
            fontSize: '36px',
            width: '400px',
            color: 'white',
            marginTop: '0px',
            marginBottom: '0px',
            textAlign: 'center'
        },
        titleBlueAnimation: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 800,
            fontSize: '36px',
            color: '#316DBA',
            textDecorationLine: 'underline', // Add underline to the text
            textAlign: 'center'
        },
        titleBlue: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 800,
            fontSize: '36px',
            color: '#316DBA',
            textAlign: 'center'
        },
        description: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            marginTop: '10px',
            fontSize: '14px',
            fontWeight: '400',
            color: '#F6F8FA',
            display: 'block',
            width: '100%',
            lineHeight: '1.4',
            textAlign: 'center',
        },
        integrationHeader: {
            display: 'flex',
            justifySelf:'center',
            alignItems: 'center',
            margin: '10px',
            justifyContent: 'left',
            flexDirection: 'row',
            textAlign: 'left'

        },
    };
    return (
        <Container data-testid="app" css={{
            position: "relative",
            textAlign: 'center',
            padding: "10px",
            backgroundColor: '#00152F',
            marginTop: '0px',
            // backgroundImage: `url(${bgImage})`,
            animation: `1s ease-in 1s 1 both ${fadein}`,
            // backgroundSize: "cover",

        }}>
            <div style={{
                height: 'auto',
                backgroundColor: '#00152F',
                backgroundSize:'contain',
                backgroundImage: `url(${bgImage})`,
                backgroundRepeat: "no-repeat",
                paddingTop: '300px',
                alignContent: 'center'
            }}>

                <Title style={styles.title}>The Right <Text style={styles.titleBlueAnimation}>{currentText}</Text></Title>
                <Title style={{ ...styles.title, paddingTop: '10px' }}>at The Right Scale</Title>
                <Title style={styles.title}>For Every <Text style={styles.titleBlue}>Location!</Text></Title>

                <Text style={styles.description}>
                    Get precise location insights:
                    <br></br>
                    just what you need, exactly where you need it.
                </Text>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    width: '100%',
                    height: '60px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                    marginTop: '40px',
                    overflow: 'hidden' // memastikan elemen tetap dalam radius border
                }}>
                    {/* Icon Search */}
                    <div
                        style={{
                            backgroundColor: '#316DBA',
                            width: '60px',
                            height: '60px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <img alt="example" src={IconSearch} />
                    </div>

                    {/* Input Field */}
                    <input
                        type="text"
                        placeholder="Search location (e.g., Cilandak)"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontSize: '16px',
                            color: '#333',
                            padding: '5px 15px' // Memberikan jarak antar teks dengan tepi
                        }}
                    />
                     
                </div>
                {results.length > 0 && (
                            <ul
                                style={{
                                    marginTop: "1px",
                                    listStyleType: "none",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    maxHeight: "300px",
                                    // maxWidth:'50%',
                                    minWidth:'50%',
                                    overflowY: "auto",
                                }}
                            >

                                {results.length > 0 && (
                                    <ul
                                        style={{
                                            marginTop: "10px",
                                            listStyleType: "none",
                                            borderRadius: "10px",
                                            backgroundColor: "#fff",
                                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                            maxHeight: "140px",
                                            overflowY: "auto",
                                        }}
                                    >
                                        {results.map((result: any, index: number) => (
                                            <li
                                                key={index}
                                                style={{
                                                    padding: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    backgroundColor: "#f9f9f9",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    marginBottom: "5px",
                                                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                                                    fontSize: "14px",
                                                    transition: "background-color 0.3s ease",
                                                }}
                                                onClick={() => handleLocationSelect(result)} // Select location and navigate
                                                onMouseEnter={(e) =>
                                                    (e.currentTarget.style.backgroundColor = "#e0e0e0")
                                                }
                                                onMouseLeave={(e) =>
                                                    (e.currentTarget.style.backgroundColor = "#f9f9f9")
                                                }
                                            >
                                                {/* Icon berdasarkan tipe */}
                                                <div
                                                    style={{
                                                        marginRight: "10px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {result.geometry.type === "Polygon" && (
                                                        <FaVectorSquare size={20} color="#217aff" />
                                                    )}
                                                    {result.geometry.type === "Line" && (
                                                        <FaRoad size={20} color="#217aff" />
                                                    )}
                                                    {result.geometry.type === "Point" && (
                                                        <FaMapMarkerAlt size={20} color="#217aff" />
                                                    )}
                                                </div>
                                                {/* Nama lokasi */}
                                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                                    {result.place_name}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </ul>
                        )}
                <div style={styles.floatingCardStyles}>
                    <div style={{
                        padding: '10px'
                    }}>
                        <div style={styles.integrationHeader}>
                            <>
                                <img alt="example" src={IconScoring} style={styles.icon} /><Text style={styles.integrationText}>People Traffic</Text>
                            </>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            padding: '10px',
                            alignItems: 'center',
                            // height: '40px',
                            backgroundColor: '#DCE1E7',
                            borderRadius: '6px',
                            margin: '0 auto',
                        }}>
                            {['By Gender', 'By Generation', 'By Hobby'].map((tab)  => (
                                <Button
                                    type="text"
                                    key={tab}
                                    id={tab}
                                    onClick={() => handleTabClickTraffic(tab)}
                                    style={{
                                        height: '32px',
                                        width: '100px',
                                        borderRadius: '6px',
                                        color: activeTabTraffic === tab ? '#333939' : '#7D8587',
                                        backgroundColor: activeTabTraffic === tab ? '#F6F8FA' : 'transparent',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        padding: '0 10px',
                                        border: activeTabTraffic === tab ? '1px solid #F6F8FA' : '1px solid transparent',
                                    }}
                                >
                                    {tab}
                                </Button>
                            ))}
                        </div>
                        {renderTabContentTraffic()}
                    </div>
                </div>
            </div>


        </Container>
    );
}

export default SectionHeroMobile;
