import React from 'react';
import { Button, notification } from 'antd';
import { convertToKm2, formatNumberReachKm2, formatNumberReachSqm, formatNumberWithDots, formatRangeNumbers, formatRangeNumbersKm2distribution } from '../../../component/newmap/helper/formatNumber';
import icAddress from '../../../asesst/Icons/interactivemap/icon/result.svg';
import icPotition from '../../../asesst/Icons/interactivemap/icon/result3.svg';
import icArea from '../../../asesst/Icons/interactivemap/icon/result2.svg';
import icDownload from '../../../asesst/new/icon/download.svg';
import icTotal from '../../../asesst/Icons/interactivemap/icon/ic-sum-total.svg'
import icavg from '../../../asesst/Icons/interactivemap/icon/ic-sum-avg.svg'
import icmin from '../../../asesst/Icons/interactivemap/icon/ic-sum-min.svg'
import icmed from '../../../asesst/Icons/interactivemap/icon/ic-sum-med.svg'
import icmax from '../../../asesst/Icons/interactivemap/icon/ic-sum-max.svg'
import icbar from '../../../asesst/Icons/interactivemap/icon/ic-sum-bar.svg'
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { RightOutlined } from '@ant-design/icons'; // Untuk ikon panah di sebelah kanan

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import GridParser from '../../../component/newmap/helper/map/geojson/grid';
import { MenuCardDownloadData } from '../../../component/menu/menu.card.download.data';
import chroma from 'chroma-js';
import { useNavigate } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface ResultCatalogProps {
  onBackClick: () => void;
  map: maplibregl.Map | null;
}

interface TrafficData {
  total: number;
  earlymorning: number;
  morning: number;
  afternoon: number;
  evening: number;
  night: number;
}

type SummaryTraficHistogram = Record<string, TrafficData>;

export const DrawerSeeData: React.FC<ResultCatalogProps> = ({ onBackClick, map }) => {
  const {
    // setDownloadData,
    setShowCardDownload,
    showCardDownload,
    // downloadData,
    metaData,
    dataLayerGroup,
    popUpFilter,
    colorGroup
  } = useReduxCatalog();
  const navigate = useNavigate();
  const grid_lib = new GridParser();
  const filteredData = dataLayerGroup.filter(item => popUpFilter.includes(item.columnName));

  const histogramData = filteredData?.[0]?.summary?.histogram;
  const topo5 = filteredData?.[0]?.summary?.top5;
  const ranges = histogramData?.map((item: { range: any; }) => item.range);
  const values = histogramData?.map((item: { value: any; }) => item.value);
  const avg = filteredData?.[0]?.summary?.avg ?? 0; // Default 0 jika undefined
  const max = filteredData?.[0]?.summary?.max ?? 0;
  const min = filteredData?.[0]?.summary?.min ?? 0;
  const median = filteredData?.[0]?.summary?.median ?? 0;

  const summaryAll = filteredData?.[0]?.summaryall ?? 0;
  const summaryAllTotal = summaryAll?.total?.total ?? 0;
  const summaryAllHistogram = summaryAll?.total?.detail ?? 0;
  const labelNonTrarfic = filteredData?.[0]?.productName ?? '';
  // trafic
  const summaryTraficHistogram: SummaryTraficHistogram = summaryAll?.total?.detail ?? {};

  const rangeColor = filteredData?.[0]?.bins ?? []; // Default array kosong jika undefined

  const binsRangeColor = Array.from(new Set<number>(rangeColor)).sort((a, b) => a - b)
  if (!binsRangeColor || binsRangeColor.length === 0) {
    // console.error("Bins range is empty or undefined.");
  }


  const goToGenerateSummaryPage = () => {
    navigate('/generate-report', { state: { dataLayerGroup,colorGroup,metaData } });
  };

  const getColorFromInterpolation = (
    value: number,
    range: number[],
    colorGroup: string[]
  ): string => {
    // Pastikan panjang colorGroup sesuai dengan panjang range
    const adjustedColorGroup = colorGroup.slice(0, range.length);

    if (!Array.isArray(adjustedColorGroup) || adjustedColorGroup.length !== range.length) {
      throw new Error("ColorGroup harus memiliki panjang yang sama dengan range.");
    }

    if (value <= range[0]) return adjustedColorGroup[0]; // Warna pertama jika nilai di bawah rentang
    if (value >= range[range.length - 1]) return adjustedColorGroup[adjustedColorGroup.length - 1]; // Warna terakhir jika nilai di atas rentang

    for (let i = 0; i < range.length - 1; i++) {
      if (value >= range[i] && value < range[i + 1]) {
        // Interpolasi linier
        const t = (value - range[i]) / (range[i + 1] - range[i]);
        return interpolateColor(adjustedColorGroup[i], adjustedColorGroup[i + 1], t);
      }
    }

    return "#000000"; // Fallback jika tidak sesuai
  };

  const interpolateColor = (color1: string, color2: string, t: number): string => {
    const c1 = chroma(color1).rgb();
    const c2 = chroma(color2).rgb();
    const interpolated = [
      Math.round(c1[0] + t * (c2[0] - c1[0])),
      Math.round(c1[1] + t * (c2[1] - c1[1])),
      Math.round(c1[2] + t * (c2[2] - c1[2])),
    ];
    return chroma(interpolated).hex();
  };
  // Konversi data ke GeoJSON
  const convertTogeojson = topo5?.map((item: { gid: string; value: number }) => {
    try {
      // Validasi data sebelum interpolasi
      if (typeof item?.value !== "number" || !item?.gid) {
        // console.error(`Invalid data for item:`, item);
        return null; // Abaikan item yang tidak valid
      }
      const colorStops = getColorFromInterpolation(item?.value, binsRangeColor, colorGroup?.color || [])
      return {
        type: "Feature",
        properties: {
          ...item,
          color: colorStops,
        },
        geometry: {
          type: "Polygon",
          coordinates: [grid_lib._gid_to_geometry(item?.gid)], // Gunakan transformasi geometris yang sesuai
        },
      };
    } catch (error) {
      console.error(`Failed to process item:`, item, error);
      return null; // Abaikan item yang gagal diproses
    }
  })
    .filter(Boolean); // Hapus elemen `null`  

  const handleCardDownloadclick = () => {
    setShowCardDownload(true);
  };

  const handleFlyTo = (item: any) => {
    if (map) {
      // Ambil koordinat dari GeoJSON (ambil titik pertama dalam polygon)
      const coordinates = item.geometry.coordinates[0][0]; // Koordinat pertama dalam polygon

      if (coordinates) {
        // Tambahkan layer dengan warna pada grid target
        const sourceId = 'highlighted-grid-source';
        const layerId = 'highlighted-grid-layer';

        // Periksa apakah source/layer sudah ada, jika ya, hapus
        if (map.getSource(sourceId)) {
          map.removeLayer(`${layerId}-outline`);
          map.removeLayer(layerId);
          map.removeSource(sourceId);
        }
        // Tambahkan source baru untuk grid
        map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [item], // Gunakan fitur GeoJSON yang diterima
          },
        });

        // Tambahkan layer baru dengan warna fill
        map.addLayer({
          id: layerId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': 'transparent',
            'fill-opacity': 1, // Transparansi
            'fill-outline-color': 'black',
          },
        });

        map.addLayer({
          id: `${layerId}-outline`, // ID unik untuk outline
          type: 'line',
          source: sourceId,
          paint: {
            'line-color': 'black', // Warna garis tepi
            'line-width': 2, // Ketebalan garis tepi
          },
        });

        // Pindahkan tampilan peta ke grid yang dituju
        map.flyTo({
          center: coordinates, // Format [longitude, latitude]
          zoom: 20,
          speed: 1.2,
          curve: 1, // Smooth fly effect
        });
      } else {
        notification.warning({
          message: 'Invalid Coordinates',
          description: 'Unable to extract coordinates from the selected feature.',
        });
      }
    } else {
      notification.warning({
        message: 'Map instance is not available',
        description: 'Ensure the map is loaded before using this feature.',
      });
    }
  };
// console.log(ranges);

  const distributionData = {
    labels:
      filteredData?.[0]?.columnName?.includes('expenditure')
        ? formatRangeNumbers(ranges)
        : filteredData?.[0]?.columnName === 'reachability_inbound_sqm' ||
          filteredData?.[0]?.columnName === 'reachability_outbound_sqm'
          ? formatRangeNumbersKm2distribution(ranges)
          : ranges,
    datasets: [
      {
        label: 'Values',
        data: values,
        backgroundColor: colorGroup?.color,
        borderRadius: 4,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 10,
        bottom: 10, // Atur padding agar label sumbu-X tidak terlalu jauh
      },
    },
    plugins: {
      legend: {
        display: false, // Sembunyikan legenda
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#FFFFFF', // Warna label x-axis
          // padding: 2, // Atur jarak label dari sumbu-X
          font: {
            size: 7, // Ubah ukuran font label y-axis

          },
        },
      },
      y: {
        ticks: {
          color: '#FFFFFF', // Warna label y-axis
          font: {
            size: 10, // Ubah ukuran font label y-axis
          },
        },
      },
    },
  };

  const labels = [labelNonTrarfic]; // Label untuk X-axis
  const sortedDatasets = Object.entries(summaryAllHistogram)
    .sort(([, valueA], [, valueB]) => (valueA as number) - (valueB as number))
    .map(([key, value], index) => ({
      label: key.replace(/_/g, ' '),
      data: [value as number], // Nilai data (harus array sejajar dengan labels)
      backgroundColor: [
        "#E57373", // Warna awal
        "#64B5F6",
        "#81C784",
        "#FFD700", // Warna tambahan
        "#FF8C00",
        "#FF69B4",
        "#BA55D3",
        "#40E0D0",
        "#7FFFD4",
        "#4682B4",
        "#DC143C",
        "#8B0000",
        "#00FA9A"
      ][index], // Warna
      borderRadius: 4, // Styling opsional
    }));
  // total stacked bar
  const dataTotal = {
    labels: labels, // Label X-axis
    datasets: sortedDatasets, // Dataset yang sudah dipetakan
  };

  const optionsTotal = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const, // Posisi legend
        labels: {
          boxWidth: 10, // Lebar kotak warna
          color: "#fff", // Warna teks legend
        },
        onClick: () => { }, // Nonaktifkan interaksi klik pada legend
      },
      title: {
        display: true,
        color: "#fff", // Warna teks legend
        font: {
          size: 18,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
    },
    scales: {
      x: {
        stacked: true, // Bar stack di X-axis
        ticks: {
          color: "#fff", // Warna teks legend
        },
      },
      y: {
        stacked: true, // Bar stack di Y-axis
        ticks: {
          font: {
            size: 8, // Ubah ukuran font label y-axis

          },
          color: "#fff", // Warna teks legend
          beginAtZero: true, // Mulai dari nol
        },
        grid: {
          color: "rgba(0, 0, 0, 0.2)", // Warna grid
        },
      },
    },
  };
  // end total stack bar
  // staked bar trafic 
  const labelsTrafic = ["Early Morning", "Morning", "Afternoon", "Evening", "Night"];
  const datasetsTrafic = Object.entries(summaryTraficHistogram).map(([key, values], index) => ({
    label: key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase()), // Format label
    data: [
      values?.earlymorning,
      values?.morning,
      values?.afternoon,
      values?.evening,
      values?.night,
    ],
    backgroundColor: ["#E57373", "#64B5F6", "#81C784", "#FFD700", "#FF8C00"][
      index % 5
    ], // Warna berdasarkan index
    borderWidth: 1,
    borderRadius: 4,
  }));

  const chartDataTrafic = {
    labels: labelsTrafic,
    datasets: datasetsTrafic,
  };

  const optionsTrafic = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const, // Posisi legend
        labels: {
          boxWidth: 10, // Lebar kotak warna
          color: "#fff", // Warna teks legend
        },
        onClick: () => { }, // Nonaktifkan interaksi klik pada legend
      },
      title: {
        // text: "Population by Time of Day",
        display: true,
        color: "#fff", // Warna teks legend
        font: {
          size: 8,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
    },
    scales: {
      x: {
        stacked: true, // Bar stack di X-axis
        ticks: {
          color: "#fff", // Warna teks legend
        },
      },
      y: {
        stacked: true, // Bar stack di Y-axis
        ticks: {
          font: {
            size: 8, // Ubah ukuran font label y-axis

          },
          color: "#fff", // Warna teks legend
          beginAtZero: true, // Mulai dari nol
        },
        grid: {
          color: "rgba(0, 0, 0, 0.2)", // Warna grid
        },
      },
    },
  };
  // end stacked bar trafic

  // useEffect(() => {
  //   console.log('showCardDownload changed:', show);
  // }, [showCardDownload]);
  return (
    <React.Fragment>
      {/* Back Arrow */}
      <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
        <span onClick={onBackClick} style={{ fontSize: '18px', fontWeight: 'bold' }}>
          ← Result Location
        </span>
      </div>
      <div style={{
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}>


        <div
          style={{
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '5px',

            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black', // Set border color to white
            paddingLeft: '10px',
            paddingRight: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
          }}
        >
          <div style={{ width: '166px' }}>
            <h4 style={{ marginBottom: '10px', fontSize: '12px' }}>{metaData?.alias}</h4>
          </div>
          <div>
            <p style={{ fontSize: '11px' }}> {metaData?.description}</p>
          </div>
        </div>

        <div
          style={{
            alignItems: 'center',
            background: '#141617',
            padding: '5px',
            borderRadius: '12px',
            marginBottom: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            border: '1px solid black', // Set border color to white
            paddingLeft: '10px',
            paddingRight: '10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <img alt="example" src={icAddress} style={{ marginRight: '10px' }} />
            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Location Address</span>
            <p style={{ margin: '5px 0 0', fontSize: '11px' }}>{metaData?.address}</p>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <img alt="example" src={icPotition} style={{ marginRight: '10px' }} />
            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Position</span>
            <p style={{ margin: '5px 0 0', fontSize: '11px' }}>
              {metaData?.centroid[1]}, {metaData?.centroid[0]}
            </p>
          </div>

          <div>
            <img alt="example" src={icArea} style={{ marginRight: '10px' }} />
            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Size of Area</span>
            <p style={{ margin: '5px 0 0', fontSize: '11px' }}>{convertToKm2(metaData?.area)} km²</p>
          </div>
        </div>


        <Button
          // key={type}
          type="primary"
          style={{
            height: '40px',
            width: '100%',
            borderRadius: '8px',
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // Membuat jarak antara ikon dan teks
            padding: '0 15px',
            fontSize: '14px',
            fontWeight: 700,
            backgroundColor: '#4A7FC0',
            color: 'white',
            border: '1px solid #4A7FC0',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onClick={handleCardDownloadclick}
        > <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="example" src={icDownload} style={{ marginRight: '10px' }} />
          Download Data
          </div>
          <RightOutlined />
        </Button>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'
          
        }}>
          <h3 style={{ margin: 0 }}>Summary</h3>
          <p
            style={{
              color: '#4A7FC0',
              textDecoration: 'none',
              fontSize: '14px',
              fontWeight: 'bold',
              cursor:'pointer'
            }}
            onClick={goToGenerateSummaryPage} // Navigasi dengan state
          >
            Generate Summary
          </p>
        </div>
        <div style={{
          padding: '10px',
          background: '#141617',
          borderRadius: '8px'
        }}>
          {filteredData?.[0]?.columnName &&
            !(
              filteredData[0].columnName.includes("poi") ||
              filteredData[0].columnName.includes("reachability") ||
              filteredData[0].columnName.includes("mortality") ||
              filteredData[0].columnName.includes("migration") ||
              filteredData[0].columnName.includes("insight")
              
            ) && (
              <div
                style={{
                  background: "#141617",
                  padding: "10px",
                  borderRadius: "8px",
                  border: "2px solid #3B669A", // Menambahkan border biru
                  textAlign: "left",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
                >
                  {/* Ikon di sebelah kiri */}
                  <img
                    src={icTotal} // Ganti URL ini dengan path ke ikon Anda
                    alt="icon"
                  // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                  />
                  <h4 style={{ fontSize: "12px", margin: 0, marginLeft: "5px" }}>
                    Total
                  </h4>
                </div>
                {dataLayerGroup.length < 1 ? (
                  <p>loading...</p>
                ) : (
                  <>
                    <h3 style={{ margin: 0 }}>
                      {filteredData?.[0]?.columnName?.includes("expenditure")
                        ? `Rp. ${formatNumberWithDots(summaryAllTotal)}`
                        : filteredData?.[0]?.columnName === "reachability_inbound_sqm" ||
                          filteredData?.[0]?.columnName === "reachability_outbound_sqm"
                          ? formatNumberReachSqm(summaryAllTotal)
                          : formatNumberWithDots(summaryAllTotal)}
                    </h3>
                    <div
                      style={{
                        height: "300px", // Atur tinggi tetap
                        width: "100%", // Sesuaikan lebar
                        marginTop:'-30px'
                      }}
                    >
                      {filteredData?.[0]?.isSeries ? (
                        <Bar data={chartDataTrafic} options={optionsTrafic} />
                      ) : (
                        <Bar data={dataTotal} options={optionsTotal} />
                      )}
                    </div>
                  </>
                )}
              </div>
            )}

          <div
            style={{
              background: '#141617',
              padding: '10px',
              borderRadius: '8px',
              border: '2px solid #3B669A', // Menambahkan border biru
              textAlign: 'left',
              marginBottom: '5px'

            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              {/* Ikon di sebelah kiri */}
              <img
                src={icavg} // Ganti URL ini dengan path ke ikon Anda
                alt="icon"
              // style={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
              <h3 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Average per Square</h3>
            </div>
            {dataLayerGroup.length < 1 ? (
              <p>loading...</p>
            ) : (
              <h3 style={{ margin: 0 }}>
                {/* {formatNumberWithDots(parseFloat(avg))} */}
                {filteredData?.[0]?.columnName?.includes('expenditure')
                  ? `${formatNumberWithDots(avg)}`
                  : filteredData?.[0]?.columnName === 'reachability_inbound_sqm' ||
                    filteredData?.[0]?.columnName === 'reachability_outbound_sqm'
                    ? formatNumberReachSqm(avg)
                    : filteredData?.[0]?.columnName?.includes('insight')
                      ? Number(avg).toFixed(2)
                      : formatNumberWithDots(avg)}
              </h3>
            )}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr', // 3 kolom
              gap: '5px',
              maxWidth: '320px', // Batasi lebar maksimum container grid
              margin: '0 auto', // Pusatkan grid secara horizontal
            }}
          >
            <div
              style={{
                background: '#141617',
                padding: '10px',
                border: '2px solid #3B669A', // Menambahkan border biru
                borderRadius: '8px',
                maxWidth: '100px',
                textAlign: 'left',
              }}
            >

              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                {/* Ikon di sebelah kiri */}
                <img
                  src={icmin} // Ganti URL ini dengan path ke ikon Anda
                  alt="icon"
                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Min</h4>
              </div>
              {dataLayerGroup.length < 1 ? (
                <p>loading...</p>
              ) : (
                <h3 style={{ margin: 0 }}>
                  {/* {formatNumberWithDots(parseFloat(min))} */}
                  {filteredData?.[0]?.columnName?.includes('expenditure')
                  ? `${formatNumberWithDots(min)}`
                  : filteredData?.[0]?.columnName === 'reachability_inbound_sqm' ||
                    filteredData?.[0]?.columnName === 'reachability_outbound_sqm'
                    ? formatNumberReachSqm(min)
                    : filteredData?.[0]?.columnName?.includes('insight')
                      ? Number(min).toFixed(2)
                      : formatNumberWithDots(min)}
                </h3>
              )}
            </div>

            <div
              style={{
                background: '#141617',
                padding: '10px',
                maxWidth: '100px',
                border: '2px solid #3B669A', // Menambahkan border biru
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                {/* Ikon di sebelah kiri */}
                <img
                  src={icmed} // Ganti URL ini dengan path ke ikon Anda
                  alt="icon"
                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Med</h4>
              </div>
              {dataLayerGroup.length < 1 ? (
                <p>loading...</p>
              ) : (
                <h3 style={{ margin: 0 }}>
                  {/* {formatNumberWithDots(parseFloat(median))} */}
                  {filteredData?.[0]?.columnName?.includes('expenditure')
                  ? `${formatNumberWithDots(median)}`
                  : filteredData?.[0]?.columnName === 'reachability_inbound_sqm' ||
                    filteredData?.[0]?.columnName === 'reachability_outbound_sqm'
                    ? formatNumberReachSqm(median)
                    : filteredData?.[0]?.columnName?.includes('insight')
                      ? Number(median).toFixed(2)
                      : formatNumberWithDots(median)}
                </h3>
              )}
            </div>
            <div
              style={{
                background: '#141617',
                padding: '10px',
                border: '2px solid #3B669A', // Menambahkan border biru
                borderRadius: '8px',
                maxWidth: '100px',
                textAlign: 'left',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                {/* Ikon di sebelah kiri */}
                <img
                  src={icmax} // Ganti URL ini dengan path ke ikon Anda
                  alt="icon"
                // style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Max</h4>
              </div>
              {dataLayerGroup.length < 1 ? (
                <p>loading...</p>
              ) : (
                <h3 style={{ margin: 0 }}>
                  {/* {formatNumberWithDots(parseFloat(max))} */}
                  {filteredData?.[0]?.columnName?.includes('expenditure')
                  ? `${formatNumberWithDots(max)}`
                  : filteredData?.[0]?.columnName === 'reachability_inbound_sqm' ||
                    filteredData?.[0]?.columnName === 'reachability_outbound_sqm'
                    ? formatNumberReachSqm(max)
                    : filteredData?.[0]?.columnName?.includes('insight')
                      ? Number(max).toFixed(2)
                      : formatNumberWithDots(max)}

                </h3>
              )}
            </div>


          </div>
          <div
            style={{
              background: '#141617',
              borderRadius: '8px',
              padding: '15px',
              marginTop: '20px',
              border: '2px solid #3B669A', // Menambahkan border biru
              height: '300px', // Tinggi khusus untuk Chart.js
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              {/* Ikon di sebelah kiri */}
              <img
                src={icbar} // Ganti URL ini dengan path ke ikon Anda
                alt="icon"
              // style={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
              <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Distribution</h4>
            </div>
            {dataLayerGroup.length < 1 ? (
              <p>loading...</p>
            ) : (
              <Bar data={distributionData} options={options} />
            )}
          </div>
          <div
            style={{
              background: '#141617',
              borderRadius: '8px',
              padding: '15px',
              border: '2px solid #3B669A', // Menambahkan border biru
              marginTop: '20px',
            }}
          >

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              {/* Ikon di sebelah kiri */}
              <img
                src={icbar} // Ganti URL ini dengan path ke ikon Anda
                alt="icon"
              // style={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
              <h4 style={{ fontSize: '12px', margin: 0, marginLeft: '5px' }}>Top 5 squares with highest number</h4>
            </div>
            {convertTogeojson?.map((number: any, index: any) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '20%',
                      backgroundColor: number?.properties?.color,
                    }}
                  >
                    
                  </div>
                 {filteredData?.[0]?.columnName?.includes('expenditure')
                    ? `Rp. ${formatNumberWithDots(number?.properties?.value)}`
                    : filteredData?.[0]?.columnName === 'reachability_inbound_sqm' ||
                      filteredData?.[0]?.columnName === 'reachability_outbound_sqm'
                      ? formatNumberReachKm2(number?.properties?.value)
                      : filteredData?.[0]?.columnName?.includes('insight')
                        ? Number(number?.properties?.value).toFixed(2)
                        : formatNumberWithDots(number?.properties?.value)}
                </div>
                <p
                  style={{ color: '#4A7FC0', fontSize: '14px', cursor: 'pointer' }}
                  onClick={() => handleFlyTo(number)}
                >
                  View
                </p>
              </div>
            ))}
          </div>

        </div>
        {showCardDownload && <MenuCardDownloadData />}
      </div>

    </React.Fragment>
  );
};
