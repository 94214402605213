import React, { useEffect, useState } from 'react';
import Container from "../../../../../style/styledComponent/Container";
import { Card, Typography, Row, Col, Button } from 'antd';
import easyIcon from '../../../../../asesst/new/icon/hand.svg';
import instanSection from "../../../../../asesst/new/img/efftorless2.svg";
import selecProd from "../../../../../asesst/new/img/instant2.svg";
import directAccess from "../../../../../asesst/new/img/agnostig2.svg";
import { fadein } from '../../../../../style/keyframes';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

function SectionEasyAccessMobile() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/map');
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const services = [
        {
            img: instanSection,
            title: "Effortless Exploration",
            description: "Explore our user-friendly and informative catalog to break through technical barriers and access the solutions you need with ease.",
        },
        {
            img: selecProd,
            title: "Instant Purchase",
            description: "Experience seamless and transparent transactions with our clear pricing and straightforward checkout process. Purchase exactly what you need without the hassle of complicated contracts or hidden fees.",
        },
        {
            img: directAccess,
            title: "Direct Access",
            description: "Easily integrate your chosen products with any GIS or BI platform, ensuring you can immediately start utilizing the data to drive your projects forward.",
        },
    ];

    const containerStyles: React.CSSProperties = {
        position: "relative",
        width: "100%",
        height: "auto",
        padding: windowWidth <= 768 ? '20px 5%' : '20px 7%',
        backgroundSize: "cover",
        backgroundColor: "#F7F9FC",
        paddingBottom: "100px",
        // display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    };
    const integrationText: React.CSSProperties = {
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        alignItems: 'left',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        textAlign: 'left',
    };

    const headerStyles: React.CSSProperties = {
        padding: '50px 0',
        textAlign: 'left',
        width: '100%',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
    };

    // const iconTextStyles: React.CSSProperties = {
    //     alignItems: 'center',
    //     display: windowWidth <= 768 ? '' : 'flex',
    // };

    const cardContainerStyles: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: services.length <= 3 ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px',
        width: '100%',
    };

    const cardStyles: React.CSSProperties = {
        width: '100%%',
        margin: '10px 0',
        position: 'relative',
        minWidth: '350px',
    };

    const titleStyle: React.CSSProperties = {
        fontWeight: '800',
        fontSize: '24px',
        color: '#333',
        marginTop: '10px',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textAlign: 'left',
    }

    return (
        <>
            <Container data-testid="app" style={containerStyles}
                css={{
                    color: '#F7F9FC',
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                }}
            >
                <div style={headerStyles}>
                    <Row justify="start" align="middle">
                        <Col xs={24} md={12}>
                            <div style={integrationText}>
                                <img alt="example" src={easyIcon} style={{
                                    marginRight: windowWidth <= 768 ? '' : '10px',
                                    marginBottom: windowWidth <= 768 ? '10px' : '0',
                                    color: '#316DBA',
                                }} />

                            </div>
                            <Text style={{ fontFamily: '"Jakarta Sans", sans-serif', color: '#316DBA', fontSize: '24px', fontWeight: '800' }}>Easy Access</Text>
                            <Title style={titleStyle}>Accessing geospatial solutions has never been simpler!</Title>
                            <Text style={{ fontFamily: '"Jakarta Sans", sans-serif', fontSize: '16px', color: '#595959', display: 'block', lineHeight: '1.5', textAlign: 'left' }}>
                                We have simplified the discovery process into just three steps, making it easy for you to find the right solution.
                            </Text>
                        </Col>
                    </Row>
                </div>
                <div style={cardContainerStyles}>
                    <Card key='1' style={cardStyles} bordered={false}
                        cover={<img alt="example" src={services?.[1]?.img} height={300} style={{ borderRadius: '0px' }} />}
                    >
                        {services.map((service, index) => (

                            <><Title style={{ fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize: '24px', fontWeight: 700 }}>{service.title}</Title><Text style={{ fontFamily: "'Plus Jakarta Sans', sans-serif", }}>{service.description}</Text></>

                        ))}
                    </Card>
                </div>
                <Button onClick={handleClick} style={

                    {
                        // width: '175px',
                        height: '50px',
                        marginTop: '10px',
                        backgroundColor: '#316DBA',
                        borderColor: '#316DBA',
                        color: 'white',
                    }
                }>
                    <span style={{ color: 'white', fontSize: '16px', fontWeight: '700px', fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Try Our Maps</span>
                </Button>
            </Container>
        </>
    );
}

export default SectionEasyAccessMobile;
