import Container from "../style/styledComponent/Container";
import { NavBar } from "../component/page/shared/nav";
// import SectionHero from "../layout/pageLayout/component/componentHome/section.hero";
// import SectionService from "../layout/pageLayout/component/componentHome/section.services";
import SectionEasyAccess from "../component/page/component/componentHome/section.easyAccess";
import SectionOneApi from "../component/page/component/componentHome/section.oneApi";
// import SectionOpenData from "../layout/pageLayout/component/componentHome/section.openData";
import SectionFooters from "../component/page/shared/footer/footer.section";
import SectionOurPartners from "../component/page/component/componentHome/section.ourPartners";
import SectionServices2 from "../component/page/component/componentHome/section.services.new";
import TalkToUsSection from "../component/page/component/componentHome/section.taklToUs";
import SectionDataPublic from "../component/page/component/componentHome/section.dataPublic";
// import SectionGranurall from "../component/page/component/componentHome/section.granural";
// import SectionHeroMerdeka from "../component/page/component/componentHome/section.hero.merdeka";
import SectionDataPublicMobile from "../component/page/component/componentHome/mobile/section.dataPublic.mobile";
// import SectionGranullarMobile from "../component/page/component/componentHome/mobile/section.granular.mobile";
import { useEffect, useState } from "react";
import SectionEasyAccessMobile from "../component/page/component/componentHome/mobile/section.easyAccess.mobile";
import SectionApiCallMobile from "../component/page/component/componentHome/mobile/section.oneApi.mobile";
import SectionOurPartnersMobile from "../component/page/component/componentHome/mobile/section.ourPartner.mobile";
import TalkToUsSectionMobile from "../component/page/component/componentHome/mobile/section.talkToUs.mobile";
// import SectionHeroMerdekaMobile from "../component/page/component/componentHome/mobile/section.merdeka.mobile";
// import SectionHero from "../component/page/component/componentHome/section.hero";
import SectionHeroMobile from "../component/page/component/componentHome/mobile/section.hero.mobile";
import SectionHeroNew from "../component/page/component/componentHome/sectio.hero.new";
import SectionInAction from "../component/page/component/componentHome/section.inAction";
import SectionHowItWork from "../component/page/component/componentHome/section.howitworks";
import SectionFAQ from "../component/page/component/componentHome/section.faq";
import SectionInActionMobile from "../component/page/component/componentHome/mobile/section.inAction.mobile";
import SectionHowItWorkMobile from "../component/page/component/componentHome/mobile/section.howItWork.mobile";
import SectionFAQMobile from "../component/page/component/componentHome/mobile/section.faqs.mobile";
// import SectionServicesMobile from "../component/page/component/componentHome/mobile/section.services.mobile";


function LandingPage() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
        <NavBar style={{ 
                background: "#00152F", 
                zIndex: 1000, 
                height: windowWidth <= 768 ? "84px" : "84px",
                width: windowWidth <= 768 ? "100%" : '100%'
            }} 
            />
            <Container id='wrapper' data-testid="app" css={{
             
                backgroundSize: "cover",
                backgroundColor:'white',
                overflowY: "scroll", // Enable vertical scroll
                "&::-webkit-scrollbar": {  // Hide scrollbar for Chrome and Safari
                    width: "0px",
                    background: "transparent",
                    display: "none",
                  },
            }}>
            
          
            { windowWidth <= 768 ? <SectionHeroMobile/> : <SectionHeroNew/> } 
            { windowWidth <= 768 ? <SectionInActionMobile/> : <SectionInAction/> }
            { windowWidth <= 768 ? <SectionHowItWorkMobile/> : <SectionHowItWork/> }
            

            { windowWidth <= 768 ? <SectionEasyAccessMobile/> : <SectionEasyAccess/> }
            { windowWidth <= 768 ? <SectionApiCallMobile/> : <SectionOneApi/> }
            { windowWidth <= 768 ? <SectionDataPublicMobile/> : <SectionDataPublic/> }

            { windowWidth <= 768 ? <SectionOurPartnersMobile/> : <SectionOurPartners/> }
            { windowWidth <= 768 ? '' : <SectionServices2/> }
            { windowWidth <= 768 ?  <SectionFAQMobile/> : <SectionFAQ/> }
            { windowWidth <= 768 ? <TalkToUsSectionMobile/> : <TalkToUsSection/> }
            <SectionFooters/>
            </Container>
        </>
    );
}

export default LandingPage;
